import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-block-base',
    templateUrl: './block-base.component.html',
    styleUrls: ['./block-base.component.scss']
})
export class BlockBaseComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
