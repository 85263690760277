import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { TableInfo } from '../../class/TableInfo';
import { GoogleMapFilter } from '../../class/Filter/GoogleMapFilter';
import { Connect } from '../../services/connect';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'google-filter-pulldown',
    templateUrl: './google-filter-pulldown.component.html',
})
export class GoogleFilterPulldownComponent implements OnInit {
    @Input() table_info: TableInfo;
    @Input() currentFilter: GoogleMapFilter;
    @Output() onSelectFilter = new EventEmitter<GoogleMapFilter>();
    @Output() onDeleteFilter = new EventEmitter<number>();
    @Output() onEditFilter = new EventEmitter<GoogleMapFilter>();
    @Output() onResetFilter = new EventEmitter<void>();

    public filters: Array<any> = [];

    constructor(
        private _connect: Connect,
        private toastr: ToastrService
    ) {}

    ngOnInit() {
        // URLからgoogle_map_filter_idを取得
        const pathSegments = window.location.pathname.split(';');
        let googleMapFilterId = null;
        for (const segment of pathSegments) {
            if (segment.startsWith('google_map_filter_id=')) {
                googleMapFilterId = parseInt(segment.split('=')[1]);
                break;
            }
        }

        // フィルター一覧を読み込み、完了後に該当のフィルターを選択
        this._connect.get(`/admin/google-map-filters/${this.table_info.table}`).subscribe(
            (response) => {
                if (response['filters']) {
                    this.filters = response['filters'].map(filter => ({
                        id: filter.id,
                        ...JSON.parse(filter.params_json)
                    }));

                    // google_map_filter_idが存在する場合、該当のフィルターを選択
                    if (googleMapFilterId) {
                        const targetFilter = this.filters.find(filter => filter.id === googleMapFilterId);
                        if (targetFilter) {
                            this.selectFilter(targetFilter);
                        }
                    }
                }
            },
            (error) => {
                this.toastr.error('フィルターの読み込みに失敗しました。', 'エラー');
                console.error('Load filters error:', error);
            }
        );
    }

    getFilterDisplayName(): string {
        if (this.currentFilter && this.currentFilter.name) {
            return this.currentFilter.name;
        }
        return '色設定なし';
    }

    selectFilter(filter: GoogleMapFilter) {
        const googleMapFilter = new GoogleMapFilter(filter);

        this.onSelectFilter.emit(googleMapFilter);
    }

    deleteFilter(id: number) {
        console.log('aaaaaaaa', id)
        if (confirm('このフィルターを削除してもよろしいですか？')) {
            this._connect.post('/admin/delete-google-map-filter', { id }).subscribe(
                (response) => {
                    if (response['success']) {
                        this.toastr.success('フィルターを削除しました。', '成功');
                        // フィルター一覧を再読み込み
                        this._connect.get(`/admin/google-map-filters/${this.table_info.table}`).subscribe(
                            (response) => {
                                if (response['filters']) {
                                    this.filters = response['filters'].map(filter => ({
                                        id: filter.id,
                                        ...JSON.parse(filter.params_json)
                                    }));
                                }
                            },
                            (error) => {
                                this.toastr.error('フィルターの読み込みに失敗しました。', 'エラー');
                                console.error('Load filters error:', error);
                            }
                        );
                        this.onDeleteFilter.emit(id);
                    } else {
                        this.toastr.error(response['message'] || '削除に失敗しました。', 'エラー');
                    }
                },
                (error) => {
                    this.toastr.error('削除中にエラーが発生しました。', 'エラー');
                    console.error('Delete filter error:', error);
                }
            );
        }
    }

    editFilter() {
        this.onEditFilter.emit(this.currentFilter);
    }

    resetFilter() {
        this.onResetFilter.emit();
    }
} 