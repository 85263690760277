<ng-container>
    <div>
        <ng-container *ngIf="!!title;else NoTitle">
            <h4 class="small-title">{{title}} &nbsp;
                <button class="btn btn-success btn-sm " (click)="grantGroupModal.show()" type="button">選択</button>
            </h4>

        </ng-container>
        <ng-template #NoTitle>
            <button class="btn btn-success btn-sm" (click)="grantGroupModal.show()" type="button">選択</button>
        </ng-template>
    </div>

    <dynamic-data-view *ngIf="!grantGroupModal.isShown" [is_view_mode]="true" [dataType]="'grant_group'" [grantGroupData]="grantGroupData"></dynamic-data-view>

    <div bsModal #grantGroupModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
         aria-hidden="true">
        <div class="modal-dialog modal-primary modal-fixed" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">ユーザー・組織選択</h4>
                    <button type="button" class="close" (click)="grantGroupModal.hide()" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body p-0">
                    <edit-component [_mode]="!value?'add':'edit'" [IS_EMBED_MODE]="true" [_id]="!value?null:value" [_table]="'grant_group'" (onSubmit)="onInputGrantGroup($event)"
                                    (onCancel)="grantGroupModal.hide()" [is_add_new_button_select_other_table]="false" [grant_kind]="grant_kind"></edit-component>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->

</ng-container>
