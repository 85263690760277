import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { TableInfo } from '../../class/TableInfo';
import { SharedService } from '../../services/shared';
import { Connect } from '../../services/connect';
import { ToastrService } from 'ngx-toastr';
import { GoogleMapFilter } from '../../class/Filter/GoogleMapFilter';
import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { Conditions } from '../../class/Conditions';
import { Condition } from '../../class/Condition';

@Component({
  selector: 'google-map-view',
  templateUrl: './google-map-view.component.html',
  styleUrls: ['./google-map-view.component.scss']
})
export class GoogleMapViewComponent implements OnInit {
  @Input() table_info: TableInfo;
  @Input() edittingCustomFilter: GoogleMapFilter;
  @Output() onClickCancelButton = new EventEmitter<void>();
  @Output() onClickSaveButton = new EventEmitter<GoogleMapFilter>();
  @Output() onClickPreviewButton = new EventEmitter<GoogleMapFilter>();

  // タブ関連のプロパティ
  public activeTab: string = 'basic'; // デフォルトで基本設定タブを選択

  constructor(
    private _share: SharedService,
    private _connect: Connect,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    if (!this.edittingCustomFilter) {
      this.edittingCustomFilter = new GoogleMapFilter();
    }
  }

  preview() {
    this.onClickPreviewButton.emit(this.edittingCustomFilter);
  }

  saveChart() {
    if (!this.edittingCustomFilter.name) {
      this.toastr.error('タイトルを入力してください。', 'エラー');
      return;
    }

    if (!this.edittingCustomFilter.address_field) {
      this.toastr.error('住所フィールドを選択してください。', 'エラー');
      return;
    }

    this.onClickSaveButton.emit(this.edittingCustomFilter);
  }

  // タブ切り替えメソッド
  changeTab(tabId: string) {
    this.activeTab = tabId;
  }

  // ドラッグアンドドロップの処理
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(
        this.edittingCustomFilter.info_modal_fields,
        event.previousIndex,
        event.currentIndex
      );
    }
  }

  // 表示項目が変更された時の処理
  onInfoModalFieldsChanged(event: { selected_field_name_a: string[] }) {
    if (event.selected_field_name_a.length > 10) {
      this.toastr.error('表示項目は10個までしか選択できません。', 'エラー');
      return;
    }
    this.edittingCustomFilter.info_modal_fields = event.selected_field_name_a;
  }

  // マーカーの色設定を追加
  addMarkerColor() {
    this.edittingCustomFilter.addMarkerColorWithConditions();
  }

  // マーカーの色設定を削除
  removeMarkerColor(index: number) {
    this.edittingCustomFilter.removeMarkerColor(index);
  }

  // 条件を追加
  addCondition(colorSetting: any) {
    if (!colorSetting.conditions) {
      colorSetting.conditions = new Conditions();
    }
    // 新しい条件を追加（デフォルト値を設定）
    const newCondition = new Condition('eq', 'id', '');
    colorSetting.conditions?.condition_a?.push(newCondition);
  }

  // 条件を削除
  removeCondition(colorSetting: any, conditionIndex: number) {
    colorSetting.conditions.deleteCondition(conditionIndex);
  }

  // 条件が変更された時の処理
  onConditionChanged(event: any, colorSettingIndex: number) {
    const colorSetting = this.edittingCustomFilter.marker_colors[colorSettingIndex];
    if (colorSetting && colorSetting.conditions) {
      // イベントから条件のインデックスと更新された条件を取得
      const { index, condition } = event;
      
      // 対応する条件を更新
      if (colorSetting.conditions.condition_a[index]) {
        colorSetting.conditions.condition_a[index] = condition;
      }

      console.log('Updated conditions:', colorSetting.conditions.condition_a);
    }
  }

  // 保存処理
  save() {
    if (!this.edittingCustomFilter) {
      this.toastr.error('設定が見つかりません。', 'エラー');
      return;
    }

    if (!this.edittingCustomFilter.name) {
      this.toastr.error('フィルター名を入力してください。', 'エラー');
      return;
    }

    // 既存のフィルターを更新する場合はIDを含める
    const params = {
      table: this.table_info.table,
      params_json: JSON.stringify(this.edittingCustomFilter.toArray())
    };
    if (this.edittingCustomFilter.id) {
      params['id'] = this.edittingCustomFilter.id;
    }

    this._connect.post('/admin/save-google-map-filter', params).subscribe(
      (response) => {
        if (response['success']) {
          // レスポンスからフィルター情報を取得
          const savedFilter = response['filter'];
          if (savedFilter) {
            // フィルターIDを更新
            this.edittingCustomFilter.id = savedFilter.id;
            // パラメータを更新
            const params = JSON.parse(savedFilter.params_json);
            Object.assign(this.edittingCustomFilter, params);
          }
          
          this.toastr.success('マーカーの色設定を保存しました。', '成功');
          
          // 保存したフィルターを適用して表示を更新
          this.onClickPreviewButton.emit(this.edittingCustomFilter);
          this.onClickCancelButton.emit(); // モーダルを閉じる
        } else {
          this.toastr.error(response['message'] || '保存に失敗しました。', 'エラー');
        }
      },
      (error) => {
        this.toastr.error('保存中にエラーが発生しました。', 'エラー');
        console.error('Save error:', error);
      }
    );
  }
} 