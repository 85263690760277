<div class="card m-4">
    <div class="card-header text-center">
        <h1 style="    font-size: 20px;">クライアント情報変更</h1>
    </div>
    <div class="card-body p-3" style="min-height: 400px;">
        <div class="form-group row">
            <div class="col-md-12 ml-4">
                <img style="width: 240px; " src="https://pigeon-fw.com//assets/cloud2/img/logo.png" class="pigeon-logo" alt="PigeonCloud">
            </div>

            <div class="col-md-12 m-4 row">
                <div class="col-md-12">
                    DB名:
                    <input class="form-control" type="text" [(ngModel)]="client_name">.pigeon-cloud.com
                    <br>
                    <button (click)="getSetting()" class="btn btn-success pull-right" [ladda]="loading">情報取得</button>
                </div>

                <ng-container *ngIf="setting">
                    <div *ngIf="payment" class="row">
                        <div class="col-md-12">
                            <h5>現在有効な決済情報</h5>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <th>プラン</th>
                                    <td>{{payment['contract_type'] == 'user_num' ? 'ユーザー数プラン' : 'ログイン数プラン'}}</td>
                                </tr>
                                <tr>
                                    <th>決済日</th>
                                    <td>{{payment['payDate']}}</td>
                                </tr>
                                <tr>
                                    <th>決済手段</th>
                                    <td>{{payment['payment_service']}}</td>
                                </tr>
                                <tr>
                                    <th>ユーザー数</th>
                                    <td>{{payment['user_num']}}</td>
                                </tr>
                                <tr>
                                    <th>追加容量</th>
                                    <td>{{payment['add_size']}}GB</td>
                                </tr>
                                <tr>
                                    <th>オプション</th>
                                    <td>
                                        <ng-container *ngFor="let op of options">
                                            <div *ngIf="payment['option'][op['type']] == 'true'">・{{op['name']}}</div>
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr>
                                    <th>決済反映日時</th>
                                    <td>{{payment['reflected']}}</td>
                                </tr>
                                <tr>
                                    <th>金額</th>
                                    <td>{{payment['amount']| number}}円 (税込み)</td>
                                </tr>

                                <tr>
                                    <th>subscriptionID</th>
                                    <td>{{payment['subscriptionID']}}</td>
                                </tr>
                                <tr>
                                    <th>orderID</th>
                                    <td>{{payment['orderID']}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr style="width:100%">
                    </div>
                    <div *ngIf="next_payment" class="row">
                        <div class="col-md-12">
                            <h5>次回決済情報</h5>
                        </div>
                        <div class="col-md-12">
                            <table class="table table-bordered">
                                <tbody>
                                <tr>
                                    <th>プラン</th>
                                    <td>{{next_payment['contract_type'] == 'user_num' ? 'ユーザー数プラン' : 'ログイン数プラン'}}</td>
                                </tr>
                                <tr>
                                    <th>決済日</th>
                                    <td>{{next_payment['payDate']}}</td>
                                </tr>
                                <tr>
                                    <th>決済手段</th>
                                    <td>{{next_payment['payment_service']}}</td>
                                </tr>
                                <tr>
                                    <th>ユーザー数</th>
                                    <td>{{next_payment['user_num']}}</td>
                                </tr>
                                <tr>
                                    <th>追加容量</th>
                                    <td>{{next_payment['add_size']}}GB</td>
                                </tr>
                                <tr>
                                    <th>オプション</th>
                                    <td>
                                        <ng-container *ngFor="let op of options">
                                            <div *ngIf="next_payment['option'][op['type']] == 'true'">・{{op['name']}}</div>
                                        </ng-container>
                                    </td>
                                </tr>
                                <tr>
                                    <th>決済反映日時</th>
                                    <td>{{next_payment['reflected']}}</td>
                                </tr>
                                <tr>
                                    <th>金額</th>
                                    <td>{{next_payment['amount']| number}}円 (税込み)</td>
                                </tr>

                                <tr>
                                    <th>subscriptionID</th>
                                    <td>{{next_payment['subscriptionID']}}</td>
                                </tr>
                                <tr>
                                    <th>orderID</th>
                                    <td>{{next_payment['orderID']}}</td>
                                </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr style="width:100%">
                    </div>

                    <div class="col-md-12 mt-2 input-group">
                        トライアル期限
                        <input class="datetime"
                               [owlDateTime]="owl_datetime"
                               [owlDateTimeTrigger]="owl_datetime"
                               [(ngModel)]="trial_expired"
                               (ngModelChange)="datetimeValueChanged($event,'trial_expired','datetime')"
                        >
                        <button class="btn btn-danger" (click)="setting['trial_expired']=null;trial_expired=null">削除（本番化)</button>
                        <owl-date-time #owl_datetime
                                       [pickerType]="'both'"
                                       [dateFormat]="'YYYY-MM-DD'"
                        ></owl-date-time>
                    </div>
                    <div class="col-md-12 mt-2 input-group">
                        期限（通常は空で、解約時に期限をセット)
                        <input type="text" [(ngModel)]="setting['expired']">
                    </div>
                    <div class="col-md-12 mt-2">
                        ユーザー数
                        <input type="text" class="form-control" [(ngModel)]="setting['max_user']">
                    </div>
                    <div class="col-md-12 mt-2">
                        追加サイズ(GB)
                        <input type="text" class="form-control" [(ngModel)]="setting['add_size']">
                    </div>
                    <div class="col-md-12 mt-2">
                        アップロード最大サイズ(MB)
                        <input type="text" class="form-control" [(ngModel)]="setting['max_upload_mb']">
                    </div>
                    <div class="col-md-12 mt-2 ">
                        契約タイプ
                        <select [(ngModel)]="setting['contract_type']" class="form-control">
                            <option [value]="'user_num'">ユーザー数</option>
                            <option [value]="'login_num'">ログイン数</option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-2 ">
                        ログインIDとして、メールアドレス以外を使用可能に変更
                        <select [(ngModel)]="setting['use_login_id']" class="form-control">
                            <option [value]="'true'">●ON</option>
                            <option [value]="'false'">OFF</option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-2 ">
                        API有効
                        <select [(ngModel)]="setting['enable_api']" class="form-control">
                            <option [value]="'true'">●ON</option>
                            <option [value]="'false'">OFF</option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-2 ">
                        配信メールオプション
                        <select [(ngModel)]="setting['mail_option']" class="form-control">
                            <option [value]="'true'">●ON</option>
                            <option [value]="'false'">OFF</option>
                        </select>
                    </div>

                    <div class="col-md-12 mt-2 ">
                        ステップメールオプション
                        <select [(ngModel)]="setting['step_mail_option']" class="form-control">
                            <option [value]="'true'">●ON</option>
                            <option [value]="'false'">OFF</option>
                        </select>
                    </div>
                    <div class="col-md-12 mt-2 ">
                        メニューフォルダ表示（多階層用。主にデヂエ）
                        <select [(ngModel)]="setting['show_only_directory_on_navmenus']" class="form-control">
                            <option [value]="'true'">●ON</option>
                            <option [value]="'false'">OFF</option>
                        </select>
                    </div>
                    <div class="mt-4">
                        <button class="btn btn-success pull-right" (click)="update()" [ladda]="loading">更新</button>
                    </div>


                    <br style="clear:both">
                    <hr style="width: 100%">

                    <h2>マスターユーザーアカウント変更</h2>
                    <div style="color:red;font-weight: bold">
                        ※クライアントに依頼/許可された場合にのみ行って下さい。
                    </div>
                    <div class="col-md-12 mt-2">
                        メールアドレス(ログインID)
                        <input type="text" class="form-control" [(ngModel)]="master_user['email']">
                    </div>
                    <div class="col-md-12 mt-2">
                        パスワード(空の場合は変更しない)
                        <input type="password" class="form-control" [(ngModel)]="master_user['password']">
                    </div>


                    <div class="mt-4">
                        <button class="btn btn-success pull-right" (click)="update_master()" [ladda]="loading">マスターユーザーアカウント情報変更</button>
                    </div>
                </ng-container>


            </div>
        </div>
    </div>

</div>

