import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {moveItemInArray} from '@angular/cdk/drag-drop';
import {Conditions} from '../../../class/Conditions';
import {TableInfo} from '../../../class/TableInfo';
import {SharedService} from '../../../services/shared';


@Component({
    selector: 'grant-check',
    templateUrl: './grant.component.html',
})

export class GrantComponent implements OnInit {
    @Input('grant_json') grant_json: string;
    @Input('table') table: string;
    @Input('view_grant_force_true') view_grant_force_true: boolean = true;
    @Input('is_show_table_grant') is_show_table_grant: boolean = true;
    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();


    public grant_value: Object = {};
    public conditions: Conditions;
    public edit_conditions: Conditions;
    public table_info: TableInfo;

    public admin_table_hide_fields: Array<Object> = [];
    public admin_table_no_edit_fields: Array<Object> = [];

    //ユーザーテーブル用

    constructor(public _share: SharedService) {

    }

    ngOnInit(): void {
        this.grant_value = {}
        try {
            console.log(this.grant_json)
            let _grant_value = JSON.parse(this.grant_json)
            if (_grant_value) {
                this.grant_value = _grant_value;
            }
        } catch (e) {
            //default
            this.grant_value = {'view': true, 'edit': true, 'summarize': true, 'add': true, 'delete': true};
        }
        this.conditions = new Conditions();
        this.edit_conditions = new Conditions();
        if (this.grant_value['condition_a']) {
            this.conditions.setByJson(JSON.stringify(this.grant_value['condition_a']))
        }
        if (this.grant_value['edit_condition_a']) {
            this.edit_conditions.setByJson(JSON.stringify(this.grant_value['edit_condition_a']))
        }
        if (this.grant_value['extend_table_hide_fields']) {
            if (this.grant_value['extend_table_hide_fields']['admin']) {
                this.admin_table_hide_fields = this.grant_value['extend_table_hide_fields']['admin'];
            }
        }

        if (this.grant_value['extend_table_no_edit_fields']) {
            if (this.grant_value['extend_table_no_edit_fields']['admin']) {
                this.admin_table_no_edit_fields = this.grant_value['extend_table_no_edit_fields']['admin'];
            }
        }

        if (this.is_show_table_grant) {
            this._share.getTableInfo(this.table).subscribe(_table_info => {
                this.table_info = _table_info

            })

        }
        if (this.view_grant_force_true) {
            this.grant_value['view'] = 'true';
        }

        this.onChangeGrant()
    }

    onChangeGrant() {
        this.grant_value['condition_a'] = this.conditions.getSearchParam();
        this.grant_value['edit_condition_a'] = this.edit_conditions.getSearchParam();

        if (this.table_info && this.table_info.table === 'admin') {
            this.grant_value['extend_table_hide_fields'] = {};
            this.grant_value['extend_table_hide_fields']['admin'] = this.admin_table_hide_fields;
            this.grant_value['extend_table_no_edit_fields'] = {};
            this.grant_value['extend_table_no_edit_fields']['admin'] = this.admin_table_no_edit_fields;
        }

        if (!this.grant_value['add']) this.grant_value['duplicate'] = false;
        console.log(this.grant_value)
        this.valueChanged.emit({
            'grant_value': JSON.stringify(this.grant_value)
        });

    }

    isCheckGrant(value) {
        if (value === 'view') {
            if (this.view_grant_force_true) {
                return true;
            }
        }
        return this.grant_value[value] === true;
    }

    getConditionsByKey(key): Conditions {
        if (key == 'condition_a') {
            return this.conditions
        } else {
            return this.edit_conditions;
        }

    }

    addGrantCondition(key = 'condition_a') {
        let conditions: Conditions = this.getConditionsByKey(key)
        conditions.addCondition();
    }

    delGrantCondition(i, key = 'condition_a') {
        let conditions: Conditions = this.getConditionsByKey(key)
        conditions.deleteCondition(i)
        this.onChangeGrant()
    }

    onGrantConditionChange(cond_index, $event, key = 'condition_a') {
        let conditions: Conditions = this.getConditionsByKey(key)
        conditions.replaceCondition($event.index, $event.condition);

        this.onChangeGrant()

    }

    addAdminTableHideField() {
        this.admin_table_hide_fields.push({
            'field': 'id'
        })
    }

    addAdminTableNoEditField() {
        this.admin_table_no_edit_fields.push({
            'field': 'id'
        })
    }

    delAdminTableHideField(i) {
        this.admin_table_hide_fields.splice(i, 1)
        this.onChangeGrant();

    }

    delAdminTableNoEditField(i) {
        this.admin_table_no_edit_fields.splice(i, 1)
        this.onChangeGrant();

    }

    drop(grant_value, event, key = 'condition_a') {
        console.log(grant_value, event, key)
        let conditions = grant_value[key]
        moveItemInArray(conditions, event.previousIndex, event.currentIndex);
        grant_value[key] = conditions
        this.conditions.setByParamAry(conditions, true)
        console.log(conditions)
        this.onChangeGrant()
    }

    showForDfault(field) {
        return field.Field!=='id' && !field.Field.startsWith('field__') && field.Field != 'google_calendar' && field.Field != 'current_at';
    }

}
