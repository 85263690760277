<div class="btn-group mr-lg-0 ml-2 mb-2" dropdown placement="bottom-left" container="body">
    <button dropdownToggle type="button" class="btn btn-sm btn-default dropdown-toggle btn-filter">
        <i class="fa fa-map-marker"></i>
        <span class="filter-label pl-1">{{getFilterDisplayName()}}</span>
        <span class="caret"></span>
    </button>

    <ul id="dropdown-google-map" *dropdownMenu class="dropdown-menu" role="menu" [ngStyle]="{'max-height':'390px'}">
        <!-- 現在のフィルターに対する操作 -->
        <ng-container *ngIf="currentFilter">
            <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)" (click)="editFilter()">
                    <i class="fa fa-pencil"></i>表示中の色設定を編集
                </a>
            </li>
            <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)" (click)="resetFilter()">
                    <i class="fa fa-times"></i>色設定を解除
                </a>
            </li>
            <li class="divider dropdown-divider"></li>
        </ng-container>

        <!-- 保存済みフィルター一覧 -->
        <ng-container *ngIf="filters.length > 0">
            <ng-container *ngFor="let filter of filters">
                <li role="menuitem">
                    <div class="d-flex justify-content-between align-items-center dropdown-item">
                        <a href="javascript:void(0)" 
                           (click)="selectFilter(filter)"
                           [class.active]="currentFilter?.name === filter.name"
                           style="flex-grow: 1;">
                            {{filter.name}}
                        </a>
                        <button class="btn btn-link btn-sm text-danger p-0 ml-2" 
                                (click)="deleteFilter(filter.id)"
                                style="font-size: 14px;">
                            <i class="fa fa-trash-o"></i>
                        </button>
                    </div>
                </li>
            </ng-container>
        </ng-container>

        <!-- フィルターが存在しない場合 -->
        <ng-container *ngIf="filters.length === 0">
            <li role="menuitem">
                <a class="dropdown-item" href="javascript:void(0)">
                    保存された色設定はありません
                </a>
            </li>
        </ng-container>
    </ul>
</div> 