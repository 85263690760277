import {SummarizeFilter} from './SummarizeFilter/SummarizeFilter';
import {Base} from '../Base';
import {Conditions} from '../Conditions';
import {Connect} from '../../services/connect';
import {TableInfo} from '../TableInfo';
import {SortParam} from './SortParam';
import {Variable} from './Variable';
import {Condition} from '../Condition';
import {SharedService} from '../../services/shared';
import {Observable} from 'rxjs/Observable';
import {ColorFilter} from '../ColorFilter';

import * as cloneDeep from 'lodash/cloneDeep';
import {Data} from '../Data';
import {Form} from '../Form';
import {DashboardContent} from '../DashboardContent';

// Google マップで利用可能なマーカーの色
export const AVAILABLE_MARKER_COLORS = [
    'red',
    'blue',
    'green',
    'yellow',
    'purple',
    'pink',
    'orange',
    'ltblue', // ライトブルー
];

export class GoogleMapFilter extends Base {

    public name: string = '';
    public info_modal_fields: Array<string> = [];
    public marker_colors: Array<{
        field: string;
        value: string;
        color: string;
        conditions?: Conditions;
    }> = [];

    constructor(hash = null) {
        super(hash);
        if (hash) {
            this.id = hash.id;
            this.name = hash.name || '';
            this.info_modal_fields = hash.info_modal_fields || [];
            this.marker_colors = (hash.marker_colors || []).map(color => ({
                ...color,
                conditions: color.conditions ? new Conditions(
                    (color.conditions.condition_a || []).map(cond => ({
                        condition: cond.condition,
                        field: cond.field,
                        value: cond.value,
                        and_or: cond.and_or,
                        use_variable: cond.use_variable,
                        inc_table: cond.inc_table,
                        inc_field: cond.inc_field,
                        inc_filter_id: cond.inc_filter_id,
                        list_date_time_search_with_no_time: cond.list_date_time_search_with_no_time,
                        use_dynamic_condition_value: cond.use_dynamic_condition_value,
                        date_relative_value: cond.date_relative_value
                    }))
                ) : new Conditions()
            }));
        } else {
            this.id = null;
            this.name = '';
            this.info_modal_fields = [];
            this.marker_colors = [];
        }

    }

    // マーカーの色設定を追加（条件付き）
    addMarkerColorWithConditions() {
        const newColorSetting = {
            field: '',
            value: '',
            color: 'red',
            conditions: new Conditions()
        };
        // 新しい条件を追加する場合は、addConditionメソッドを使用
        newColorSetting.conditions.addCondition('eq', 'id', '');
        this.marker_colors.push(newColorSetting);
    }

    // マーカーの色設定を削除
    removeMarkerColor(index: number) {
        this.marker_colors.splice(index, 1);
    }

    // 保存用のデータ変換メソッド
    toArray(): Object {
        return {
            name: this.name,
            info_modal_fields: this.info_modal_fields,
            marker_colors: this.marker_colors.map(color => ({
                field: color.field,
                value: color.value,
                color: color.color,
                conditions: color.conditions ? {
                    condition_a: color.conditions.condition_a.map(cond => ({
                        condition: cond.condition,
                        field: cond.field,
                        value: cond.value,
                        and_or: cond.and_or,
                        use_variable: cond.use_variable,
                        inc_table: cond.inc_table,
                        inc_field: cond.inc_field,
                        inc_filter_id: cond.inc_filter_id,
                        list_date_time_search_with_no_time: cond.list_date_time_search_with_no_time,
                        use_dynamic_condition_value: cond.use_dynamic_condition_value,
                        date_relative_value: cond.date_relative_value
                    }))
                } : null
            }))
        };
    }

    // 条件に基づいてマーカーの色を取得
    getMarkerColorByConditions(data: Data): string {
        let markerColor = 'red'; // デフォルトの色

        // __map_color_filter_X フィールドを確認
        for (let i = 0; i < this.marker_colors.length; i++) {
            const filterKey = `__map_color_filter_${i}`;
            if (data.raw_data[filterKey] === "true") {
                markerColor = this.marker_colors[i].color;
            }
        }
        
        return markerColor;
    }

    // 利用可能なマーカー色の配列を取得
    static getAvailableColors(): string[] {
        return AVAILABLE_MARKER_COLORS;
    }
}
