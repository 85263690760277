<div bsModal #importMenu="bs-modal" class="modal fade small-dialog" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-upload"></i> テーブル作成</h4>
                <button type="button" class="close" (click)="importMenu.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>
                    <button type="button" class="btn btn-outline-primary mr-3" (click)="openTsvImportMenu()">
                        <i class="fa fa-download"></i> エクセルからインポート
                    </button>
                </p>
                <p>
                    <button type="button" class="btn btn-outline-primary" (click)="navigateDashboard()">
                        <i class="fa fa-download"></i> 手動でテーブルを作成
                    </button>
                </p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="importMenu.hide()">キャンセル</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #tsvImportMenu="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-upload"></i> エクセルからインポート</h4>
                <button type="button" class="close" (click)="tsvImportMenu.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label class="col-md-3 form-control-label">
                    テーブル名
                    <span class="text-danger">※</span>
                </label>
                <div class="col-md-12 col-lg-7">
                    <input [(ngModel)]="datasetName" type="text" class="form-control">
                </div>
                <label class="col-md-12 form-control-label">
                    Excelやスプレッドシートからペーストしてください。<br>
                    （１行目がヘッダーとなります。）
                </label>
                <div class="col-md-12 col-lg-7">
                    <textarea class="form-control" rows="5" [(ngModel)]="datasetTsvText"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-outline-primary" (click)="openTsvConfirmMenu()">
                    <i class="fa fa-download"></i> インポート
                </button>
                <button type="button" class="btn btn-secondary" (click)="tsvImportMenu.hide()">キャンセル</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

<div bsModal #tsvConfirmMenu="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"> エクセルからインポート ({{tsvDataCount}}件)</h4>
                <button type="button" class="close" (click)="tsvConfirmMenu.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <label class="col-md-3 form-control-label">
                    テーブル名
                    <span class="text-danger">※</span>
                </label>
                <div class="col-md-9 col-lg-7">
                    <input [(ngModel)]="datasetName" type="text" class="form-control">
                </div>
                <label class="col-md-9 form-control-label">
                    <br>
                    項目確認
                </label>
                <table class="table">
                    <ng-container *ngFor="let column of datasetColumnNameList" class="nav-item">
                        <tr>
                            <td><input type="text" class="form-control" [(ngModel)]="column['name']"/></td>
                            <td>
                                <select class="form-control" [(ngModel)]="column['type']">
                                    <option *ngFor="let type of column['type_list']" [ngValue]="type">
                                        {{getTypeName(type)}}
                                    </option>
                                </select>
                            </td>
                        </tr>
                    </ng-container>
                </table>

            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-default" [ladda]="loading" (click)="back()">
                    戻る
                </button>
                <button type="button" class="btn btn-outline-primary" [ladda]="loading" (click)="importTsv()">
                    <i class="fa fa-download"></i> インポート
                </button>
                <button type="button" class="btn btn-secondary" (click)="tsvConfirmMenu.hide()">キャンセル</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->

