import { Component, OnInit, Input, ViewChild, Output, EventEmitter } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router'

import { TableInfo } from '../class/TableInfo';
import { SharedService } from '../services/shared';
import ToastrService from '../toastr-service-wrapper.service';

import { Connect } from '../services/connect';
import { Data } from '../class/Data';
import {Form} from '../class/Form';



@Component({
  selector: 'branc-con-process-trans',
  templateUrl: './process-trans.component.html',
  styleUrls: ['./process-trans.component.css']
})
export class BrancConProcessTrans implements OnInit {

  @Input() sending: boolean = false;
  @ViewChild('branConProsessTransModal') branConProsessTransModal: any;

  public id: string;
  public data: any = null;
  public table_info: TableInfo = null;
  public trans_fields: any = null;
  public class_options = [];
  public prosess_class:string = null;
  public product_name:string = null;
  public input_weight:string = null;

  @Input() branc_con_data: any = null;
  @Output() load: EventEmitter<any> = new EventEmitter();
  @Input() table: string;


  constructor(
    public _share: SharedService,
    private _connect: Connect,
    private _route: ActivatedRoute,
    private toasterService: ToastrService) { }

  ngOnInit(): void {
 
  }

  ngOnChanges() {
    if (!this.branc_con_data) return;
    this.data = this.branc_con_data.data;
    this.table_info = this.branc_con_data.table_info;
    this.id = this.data.raw_data['id'];

    if(this.trans_fields) return;
    this._connect.get('/admin/bran-con/fields/' + this.table, null, null, true).subscribe((data_hash) => {
      this.trans_fields = data_hash.fields
      this.product_name = this.data.view_data['field__' + this.trans_fields['branc_procuct_name'].id]
      let menu_object = JSON.parse(this.trans_fields['branc_class'].field_menu_config_json);
      this.class_options = menu_object.items
    })
  }



  public hide() {
    // this.sending = false;
    this.branConProsessTransModal.hide()
  }
  public show() {
    this.branConProsessTransModal.show()
  }


  submit() {
    if (this.sending) return;
    // 必須チェック
    if (!this.input_weight) {
      this.toasterService.error('投入重量は必須です');

      return;
    }

    this.sending = true;
    this._connect.post('/admin/bran-con/process-trans', {
      prosess_class: this.prosess_class,
      product_name: this.product_name,
      input_weight: this.input_weight,
      table: this.table,
      id: this.id,
      raw_data: this.data.raw_data,
      trans_fields: this.trans_fields
    }, {}, false).subscribe((res) => {
      this.sending = false;
      this.load.emit();      
      this.hide();

    }, (error) => {
      this.sending = false;
    })
}



}
