<div bsModal [config]="{backdrop: 'static', keyboard: false}" #modal='bs-modal' class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document" *ngIf="block && block.type == blockClass.TYPE_TRIGGER">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">トリガ設定</h4>
            </div>
            <div class="modal-body">

                <div class="mt-2">
                    <label>トリガタイプ:</label>
                    <select class="form-control" [(ngModel)]="block.trigger_type">
                        <option *ngFor="let option of _shared.triggerTypeOptions" [value]="option.value">{{ option.label }}</option>
                    </select>
                </div>

                <!-- 以下、各トリガタイプごとの設定フィールド -->
                <div *ngIf="block.trigger_type === 'weekly'">
                    <label>曜日:</label>
                    <select class="form-control" [(ngModel)]="block.dayOfWeek">
                        <option value="0">日曜日</option>
                        <option value="1">月曜日</option>
                        <option value="2">火曜日</option>
                        <option value="3">水曜日</option>
                        <option value="4">木曜日</option>
                        <option value="5">金曜日</option>
                        <option value="6">土曜日</option>
                    </select>
                </div>

                <div *ngIf="block.trigger_type === 'monthly'">
                    <label>日付:</label>
                    <input type="number" class="form-control" [(ngModel)]="block.day" min="1" max="31">
                </div>

                <div *ngIf="block.trigger_type === 'yearly'">
                    <label>月:</label>
                    <input type="number" class="form-control" [(ngModel)]="block.month" min="1" max="12">
                    <label>日:</label>
                    <input type="number" class="form-control" [(ngModel)]="block.day" min="1" max="31">
                </div>

                <div *ngIf="block.trigger_type === 'daily' || block.trigger_type=='weekly'  || block.trigger_type=='monthly' || block.trigger_type=='yearly'">
                    <label>時間:</label>
                    <input type="time" class="form-control" [(ngModel)]="block.time">
                </div>

                <!-- 省略: データイベントタイプの選択 -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modal.hide()">キャンセル</button>
                <button type="button" class="btn btn-success" (click)="save()">保存</button>
            </div>
        </div>
    </div>
</div>

