<div *ngIf="loading;then loading_block else loaded_block"></div>
<ng-template #loading_block>
    <div class="loader">Loading...</div>

</ng-template>
<ng-template #loaded_block>
    <ng-container *ngIf="data==null">
        <div class="data_not_found" style="padding:40px;text-align:center">
            DATA NOT FOUND
        </div>
    </ng-container>

    <ng-container *ngIf="data!=null">
        <admin-view-table [filter]="customFilter" [table]="table_info.table" [fields]="fields" [forms]="table_info.forms" [data]="data"
                          [extend_headers]="extend_headers" [selectDataMode]="selectDataMode" [relation_table_a]="data.relation_table_a"></admin-view-table>

        <!-- 子テーブル -->
        <ng-container *ngFor="let child of data.getFilteredChildAry(this.customFilter)">
            <ng-template
                [ngIf]="data.child_data_by_table[child.table] && data.child_data_by_table[child.table].length > 0 && child.is_child_form">
                <h4 class="mt-5">{{child.getLabel()}}</h4>

                <ng-container *ngIf="child.menu.multiple_mode=='normal'">
                    <ng-container *ngFor="let data of data.child_data_by_table[child.table]">
                        <div class="" *ngIf="child.forms != undefined">
                            <admin-view-table class="view-children" [ignore_fieldname_a]="['created','updated','id','order','admin_id','updated_admin_id']"
                                              [fields]="child.fields" [forms]="child.forms" [data]="data" [table]="child.menu.table" [selectDataMode]="selectDataMode">
                            </admin-view-table>
                        </div>
                        <div style="display:flex;justify-content: flex-end; ">
                            <ng-container *ngFor="let button of child.menu.button_functions">
                                <input class="form-control" style="width:40px" [OnlyNumber] type="text" [(ngModel)]="button.value">
                                <button class="btn btn-info ml-1 mt-0" (click)="call_func(button,data.raw_data['id'])">{{button.button_label}}</button>
                            </ng-container>
                        </div>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='table'">
                    <admin-view-table-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                        [data_a]="data.child_data_by_table[child.table]"></admin-view-table-list>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='image'">
                    <admin-view-image-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                           [data_a]="data.child_data_by_table[child.table]"></admin-view-image-list>
                </ng-container>
                <ng-container *ngIf="child.menu.multiple_mode=='chip' || child.menu.multiple_mode=='chip_toxi'">
                    <admin-view-chip-list [fields]="child.fields" [forms]="child.forms" [table]="child.menu.table"
                                          [data_a]="data.child_data_by_table[child.table]"></admin-view-chip-list>
                </ng-container>
            </ng-template>
        </ng-container>
    </ng-container>
</ng-template>
