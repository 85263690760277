<ng-container>
    <style>
        .holder {
            width: 480px;
            text-align: center;
            margin: 0 auto;
            padding-top: 120px;
        }

        .holder h1 {
            font-family: 'loveloblack', sans-serif;
            font-size: 5em;
            color: #2d2d2d;
            text-shadow: 3px 3px 0 #e3e3e3;
            line-height: 27px;
            margin-top: 12px;
            margin-bottom: 10px;
        }

        .holder h1 span.tbl {

            font-family: Dosis, Tahoma, sans-serif;
            font-size: 35px;
            color: #2d2d2d;
            line-height: 1em;
            font-weight: bold;
            letter-spacing: -1px;
            line-height: 1;
            text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.3);
        }


        .holder h1 span {
            font-family: Dosis, Tahoma, sans-serif;
            font-size: 1em;
            color: #3d9df8;
            line-height: 1em;
            font-weight: bold;
            letter-spacing: -1px;
            line-height: 1;
            text-shadow: -1px 1px 1px rgba(0, 0, 0, 0.3);
        }


        p {
            font-size: 18px;
            font-weight: 600;
            color: #13447E;
            font-family: 'Neuton', serif;
        }
    </style>
    <div class="holder">
        <img src="https://pigeon-fw.com/assets/images/logo1.png" style="width:250px;"/>
        <h1><span class="tbl">現在緊急メンテナンス中です。</span></h1>
        <p><span class="tbl">ご不便をおかけしており申し訳ございませんが、<br>しばらくお待ち下さい。<br>緊急の場合は<a href="mailto:info@loftal.jp">info@loftal.jp</a>までお問い合わせ下さい.</span>
        </p><br>
        <br/>
    </div>
</ng-container>
