<div class="comment-message p-2 mt-2">
    <div class="text-truncate font-weight-bold user_name flex">
        <div class="avatar">
            <img src="{{comment.user.image_url}}" class="img-avatar">
            <!--<span class="avatar-status badge-success"></span>-->
        </div>
        <div class="username">
            {{comment.user.name}}
        </div>
        <i style="padding-top: 2px;" *ngIf="!comment.workflow_status" class="fa fa-trash pull-right text-danger pointer flex-c-right" (click)="delete()"></i>
    </div>
    <div class="mt-1 comment-body" [innerHTML]="content|safeHtml">{{content}}&nbsp;</div>
    <div class="flex mt-2">
        <div class="workflow_status_wrapper">
            <div *ngIf="comment.workflow_status==='rejected'" class="workflow_status btn-danger">否認</div>
            <div *ngIf="comment.workflow_status==='accepted'" class="workflow_status btn-success">承認</div>
            <div *ngIf="comment.workflow_status==='applying'" class="workflow_status btn-primary">申請</div>
            <div *ngIf="comment.workflow_status==='withdraw'" class="workflow_status btn-danger">取り下げ</div>
        </div>
        <div class="flex-c-right">
            <small class="date float-right mt-1">{{comment.date_str}}&nbsp;</small>
        </div>
    </div>
</div>
