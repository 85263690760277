<div bsModal #expenseAllocationModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
   aria-labelledby="myModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-default" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h4 class="modal-title">
               経費按分
            </h4>
         </div>
         <div class="modal-body">
            <div class="expense_amount">
               経費金額&nbsp;&nbsp;
               <input [(ngModel)]="expense_amount" OnlyNumber type="text" class="form-control w-60 ml-24">&nbsp; kg
            </div>
            <table>
               <tr>
                  <th>原価金額</th>
                  <th>仕入金額</th>
                  <th>金額</th>
                  <th>按分率</th>
                  <th>按分金額(税抜)</th>
               </tr>
               <ng-container *ngFor="let item of expense_allocation_data; let i = index">
                  <tr>
                     <td><input type="radio" [name]="'option-' + item.raw_data['id']"
                           (change)="onSelectionChange(item.raw_data['id'], 'branc_cost_amount', i)"></td>
                     <td><input type="radio" [name]="'option-' + item.raw_data['id']"
                           (change)="onSelectionChange(item.raw_data['id'], 'branc_buy_amount', i)"></td>
                     <td>
                        <ng-container *ngIf="price_kind[item.raw_data['id']] === 'branc_cost_amount'">
                           {{ item.raw_data['field__' + branc_con_fields['branc_cost_amount']['id']] }}
                        </ng-container>
                        <ng-container *ngIf="price_kind[item.raw_data['id']] === 'branc_buy_amount'">
                           {{ item.raw_data['field__' + branc_con_fields['branc_buy_amount']['id']] }}
                        </ng-container>
                     </td>
                     <td>
                        <ng-container *ngIf="price_kind[item.raw_data['id']] === 'branc_cost_amount'">
                           {{((item.raw_data['field__' + branc_con_fields['branc_cost_amount']['id']] / total_price) *
                           100).toFixed(2)}} %
                        </ng-container>
                        <ng-container *ngIf="price_kind[item.raw_data['id']] === 'branc_buy_amount'">
                           {{((item.raw_data['field__' + branc_con_fields['branc_buy_amount']['id']] / total_price) *
                           100).toFixed(2)}} %
                        </ng-container>
                     </td>
                     <td>
                        {{setApportionedAmountExcludingTax(i, item, price_kind[item.raw_data['id']])}}

                     </td>
                  </tr>
               </ng-container>
               <tr class="no-style">
                  <td></td>
                  <td>按分元合計金額</td>
                  <td>{{total_price }}</td>
                  <td>{{totalPercentage.toFixed(0)}}%</td>
                  <td></td>
               </tr>
            </table>
            <div class="modal-footer">
               <button type="button" class="btn btn-secondary" (click)="hide()">キャンセル</button>
               <button type="button" class="btn btn-primary" (click)="submit()" [ladda]="sending">登録</button>
           </div>
         </div>
      </div>
   </div>
</div>