import {Data} from '../class/Data';
import {CrossTableHeader} from '../class/CrossTableHeader';
import {CustomFilter} from '../class/Filter/CustomFilter';
import {TableInfo} from '../class/TableInfo';

export class RecordListService {
    public data: Array<Object> = null;
    public data_a: Array<Data> = null;
    public crossTableHeader: CrossTableHeader = null;

    public constructor(response_list_data: Object, customFilter: CustomFilter, table_info: TableInfo) {
        this.data_a = [];
        if (customFilter && customFilter.summarizeFilter && customFilter.summarizeFilter.cross_table) {
            response_list_data['cross_data_a'].forEach(_data => {
                let newdata = new Data(table_info);
                newdata.setInstanceData(_data)
                this.data_a.push(newdata);
            })

            this.crossTableHeader = new CrossTableHeader(response_list_data['cross_horizontal_header_a'], response_list_data['cross_vertical_summarize_a'], response_list_data['summarize_by_record'])
            console.log('CROSS HEADER')
            console.log(this.crossTableHeader)
        } else if (response_list_data['data'] && response_list_data['data']['data']) {
            //summarize mode
            this.data = response_list_data['data']
        } else if (response_list_data['data_a']) {
            //summarize mode
            response_list_data['data_a'].forEach(_data => {
                let newdata = new Data(table_info);
                newdata.setInstanceData(_data)
                this.data_a.push(newdata);
            })
        } else {
            response_list_data['data'].forEach(_data => {
                let newdata = new Data(table_info);
                newdata.setInstanceData(_data)
                this.data_a.push(newdata);
            })
        }


    }


}
