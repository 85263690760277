<div id="paypal_script">

</div>
<div class="card" *ngIf="!loading">
    <div class="loader" *ngIf="loading || sending || notiSending;else NotLoading">Loading...</div>
    <ng-template #NotLoading>
        <div class="form-group row card-header text-center">
            <h1 style="    font-size: 20px;">利用料金のお支払い</h1>
        </div>
        <!--        <div class="card-body" *ngIf="current_payment_service=='paypal'">-->
        <!--            <div class="form-group row">-->
        <!--                <div class="col-md-12">-->
        <!--                    <div class="white-box m-4">-->
        <!--                        <div *ngIf="is_update" class="row">-->
        <!--                            <div class="col-md-4">-->
        <!--                                <h5>現在のプラン</h5>-->
        <!--                            </div>-->
        <!--                            <div class="col-md-6">-->
        <!--                                <div>-->
        <!--                                    <b>プラン</b>: {{_share.cloud_setting['contract_type'] == 'user_num' ? 'ユーザー数プラン' : 'ログイン数プラン'}}-->
        <!--                                </div>-->
        <!--                                <div>-->
        <!--                                    <b>ユーザー数</b>: {{_share.cloud_setting['max_user']}}-->
        <!--                                </div>-->
        <!--                                <div>-->
        <!--                                    <b>追加容量</b>: {{_share.cloud_setting['add_size']}}GB-->
        <!--                                </div>-->
        <!--                                <div>-->
        <!--                                    <b>オプション</b>:-->
        <!--                                    <ng-container *ngFor="let op of options">-->
        <!--                                        <div *ngIf="_share.cloud_setting[op['type']] == 'true'" style="margin-left:15px">-->
        <!--                                            ・{{op['name']}}-->
        <!--                                        </div>-->
        <!--                                    </ng-container>-->
        <!--                                </div>-->
        <!--                            </div>-->
        <!--                            <hr style="width:100%">-->
        <!--                        </div>-->

        <!--                        <div>-->
        <!--                            決済方法がpaypalから変更となりました。<br>-->
        <!--                            ユーザー数変更の場合、お手数ですが下記よりお問い合わせください。<br>-->
        <!--                            sales@loftal.jp-->
        <!--                        </div>-->
        <!--                    </div>-->
        <!--                </div>-->
        <!--            </div>-->

        <!--        </div>-->
        <div class="card-body" style="min-height: 80vh;">
            <div class="form-group row">
                <div class="col-md-12 ml-4">
                    <img style="width: 240px; " src="https://pigeon-fw.com//assets/cloud2/img/logo.png" class="pigeon-logo" alt="PigeonCloud">
                </div>

                <div class="col-md-12" style="margin: 0 auto;text-align: center;" *ngIf="current_payment_service!='paypal'">
                    <button *ngIf="is_update && !sentRequest" class="btn btn-info" (click)="makePayment(true)">
                        クレジットカード情報更新
                    </button>
                    <br>
                    <div *ngIf="current_payment_service=='paypal'" style="    margin-top: 10px;">
                        ※決済サービスがpaypalからstripeに変更となります。
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="white-box mb-4" style="max-width:690px;    margin: 6vh auto;">
                        <h2 class="text-center mt-2" style="    font-size: 23px; margin-bottom: 38px;">有料プラン契約
                        </h2>
                        <ng-container *ngIf="sentRequest || notiSent;else NotPaid;">
                            <div *ngIf="is_update">
                                <div style="text-align: center">
                                    <ng-container *ngIf="updated_status==='update_success'">
                                        プランの変更リクエストが完了しました。<br>
                                        <a href="/admin/dashboard">ダッシュボードに戻る</a>
                                    </ng-container>
                                    <ng-container *ngIf="updated_status==='update_cancel'">
                                        <div class="text-danger">プラン変更リクエストに失敗しました。</div>
                                        <a href="/admin/dashboard">ダッシュボードに戻る</a>

                                    </ng-container>
                                    <!--                                    <ng-container *ngIf="!updated_status">-->
                                    <!--                                        <ng-container *ngIf="approve_url;else NoApproveUrl;">-->
                                    <!--                                            <div class="text-danger">※まだ変更処理は完了していません。</div>-->
                                    <!--                                            下記より支払いを行うとユーザー数リクエストが送信されます。<br>-->
                                    <!--                                            <br>-->
                                    <!--                                            <button class="btn btn-success" (click)="open_approval()">-->
                                    <!--                                                支払い変更設定-->
                                    <!--                                            </button>-->
                                    <!--                                        </ng-container>-->
                                    <!--                                        <ng-template #NoApproveUrl>-->
                                    <!--                                            ユーザー数変更リクエストを送信しました。<br>-->
                                    <!--                                            <a href="/admin/dashboard">ダッシュボードに戻る</a>-->
                                    <!--                                        </ng-template>-->

                                    <!--                                    </ng-container>-->
                                </div>
                            </div>
                            <div *ngIf="!is_update && sentRequest">
                                <div style="text-align: center">
                                    お支払いが完了しました。<br>
                                    ご契約頂き、ありがとうございます。<br>
                                    <a href="/admin/dashboard">ダッシュボードに戻る</a>

                                </div>
                            </div>
                            <div *ngIf="!is_update && notiSent">
                                <div style="text-align: center">
                                    有料登録申請が完了しました。<br>
                                    担当者よりご連絡致します。<br>
                                    <a href="/admin/dashboard">戻る</a>

                                </div>
                            </div>

                        </ng-container>
                        <ng-template #NotPaid>

                            <div *ngIf="is_update && _share.stripe_credit_card['last4'] != undefined" class="row">
                                <div class="col-md-4">
                                    <h5>登録カード</h5>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-bordered">
                                        <tbody>
                                            <tr>
                                                <th> 登録カード番号</th>
                                                <td>
                                                    xxxx {{ _share.stripe_credit_card['last4'] }}
                                                </td>
                                            </tr>
                                            <tr>
                                                <th>有効期限</th>
                                                <td>
                                                    {{ _share.stripe_credit_card['exp_month']  }} / {{ _share.stripe_credit_card['exp_year'] }}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                 <hr style="width:100%">
                            </div>

                            <div *ngIf="is_update" class="row">
                                <div class="col-md-4">
                                    <h5>現在のプラン</h5>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <th>プラン</th>
                                            <td>{{_share.cloud_setting['contract_type'] == 'user_num' ? 'ユーザー数プラン' : 'ログイン数プラン'}}</td>
                                        </tr>
                                        <tr>
                                            <th>ユーザー数</th>
                                            <td>{{_share.cloud_setting['max_user']}}</td>
                                        </tr>
                                        <tr>
                                            <th>追加容量</th>
                                            <td>{{_share.cloud_setting['add_size']}}GB</td>
                                        </tr>
                                        <tr>
                                            <th>オプション</th>
                                            <td>
                                                <ng-container *ngFor="let op of options">
                                                    <div *ngIf="_share.cloud_setting[op['type']] == 'true'">・{{op['name']}}</div>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr *ngIf="current_amount">
                                            <th>金額</th>
                                            <td>{{current_amount| number}}円 (税込み)</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <hr style="width:100%">
                            </div>
                            <div *ngIf="is_update && next_plan" class="row">
                                <div class="col-md-4">
                                    <h5>翌月からのプラン</h5>
                                </div>
                                <div class="col-md-6">
                                    <table class="table table-bordered">
                                        <tbody>
                                        <tr>
                                            <th>決済予定日</th>
                                            <td>{{next_plan['next_payment_date']}}</td>
                                        </tr>
                                        <tr>
                                            <th>プラン</th>
                                            <td>{{next_plan['contract_type'] == 'user_num' ? 'ユーザー数プラン' : 'ログイン数プラン'}}</td>
                                        </tr>
                                        <tr>
                                            <th>ユーザー数</th>
                                            <td>{{next_plan['max_user']}}</td>
                                        </tr>
                                        <tr>
                                            <th>追加容量</th>
                                            <td>{{next_plan['add_size']}}GB</td>
                                        </tr>
                                        <tr>
                                            <th>オプション</th>
                                            <td>
                                                <ng-container *ngFor="let op of options">
                                                    <div *ngIf="next_plan['options'][op['type']]">・{{op['name']}}</div>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <th>金額</th>
                                            <td>{{next_plan['amount']| number}}円 (税込み)</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <hr style="width:100%">
                            </div>

                            <h3 class="mt-3">プラン変更</h3>
                            <div class="mt-4">
                                <ng-container *ngIf="is_update;else IsAdd;">
                                    <span class="text-danger text-bold">※プランを変更する場合、即時適用されます。</span><br>
                                    ※増額分は日割り計算され、次回支払時に請求が行われます. (ユーザー数を減らした場合等、減額分の日割りの返金はございませんので、ご注意ください。）<br>
                                </ng-container>
                                ※最低5ユーザーからの契約となります。<br>
                                <ng-template #IsAdd>
                                    ※毎月の費用発生となり、自動継続となります。<br>
                                </ng-template>
                            </div>
                            <div class="row p-4">
                                <table class="table table-bordered">
                                    <tr>
                                        <th style="    vertical-align: middle;">
                                            変更後ユーザー数
                                        </th>
                                        <td>
                                            <ng-container>
                                                <label>
                                                    <input type="radio" name="contract_type" class="input_contract_type"
                                                           [(ngModel)]="contract_type" (change)="onChangePlan()"
                                                           value="user_num">
                                                    ユーザー数
                                                </label>
                                                <br>
                                                <label>
                                                    <input type="radio" name="contract_type" class="input_contract_type"
                                                           [(ngModel)]="contract_type" (change)="onChangePlan()"
                                                           value="login_num">
                                                    ログイン数
                                                </label>
                                            </ng-container>
                                            <br/>
                                            <input style="width: 120px;" type="number" id="user_input" class="form-control mr-3" [(ngModel)]="quantity" (input)="onQuantityChange($event.target.value)">ユーザー
                                            <div class="text-danger" *ngIf="!is_valid_num">
                                                ※ユーザー数は5~3000の範囲で選択して下さい。
                                            </div>

                                        </td>
                                    </tr>
                                    <tr>

                                        <th style="    vertical-align: middle;">
                                            追加容量オプション
                                        </th>
                                        <td>
                                            デフォルト: 100GB<br>
                                            <select
                                                class="form-control custom"
                                                style="width:300px"
                                                (change)="onChangePlan()"
                                                [(ngModel)]="add_size"
                                            >
                                                <!-- from 100 to 2000 -->
                                                <option [value]="0">無し</option>
                                                <option *ngFor="let value of size_a" [value]="value">+{{ value }}GB</option>


                                            </select>

                                        </td>
                                    </tr>
                                    <tr>

                                        <th style="    vertical-align: middle;">
                                            オプション
                                        </th>
                                        <td>
                                            <ng-container *ngFor="let op of options">
                                                <mat-checkbox class="btn-block"
                                                              labelPosition="after"
                                                              [(ngModel)]="op.checked" (change)="onChangePlan()">
                                                    {{op.name}}{{op.price_str}}
                                                </mat-checkbox>
                                            </ng-container>

                                        </td>
                                    </tr>
                                </table>
                                <div class="col-md-6 text-center">
                                </div>
                                <div class="col-md-6 text-center" style="    font-size: 16px; font-weight: bold;">
                                    月額 <span id="price_calc">{{price | number}}</span> &nbsp; 円(税込)
                                </div>

                            </div>
                            <ng-container *ngIf="is_update;else NotUpdate">
                                <ng-container *ngIf="is_valid_num">
                                    <ng-container *ngIf="current_payment_service=='paypal'">
                                        <button class="btn btn-success"
                                                (click)="makePayment()"
                                                style=" width: 100%; padding: 15px; background: #ffc439; font-size: 20px; border-color: #ffc439; color: #000; font-weight: bold;">
                                            契約更新を行う
                                        </button>
                                        <div style="    margin-top: 10px;">
                                            ※決済サービスがpaypalからstripeに変更となります。
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="current_payment_service=='stripe'">
                                        <button class="btn btn-success pull-right" (click)="updatePaymentModal.show()">契約更新
                                        </button>
                                    </ng-container>
                                </ng-container>
                                <br style="clear:both">

                            </ng-container>
                            <ng-template #NotUpdate>
                                <div class="form-group row" *ngIf="is_valid_num">
                                    <div class="col-md-12 text-center mt-2">
                                        <mat-checkbox class="btn-block" id="aggrement_checkbox"
                                                      labelPosition="after"
                                                      [(ngModel)]="agreed">
                                            <a href="https://pigeon-fw.com/terms" target="_blank">利用規約</a>に同意する
                                        </mat-checkbox>
                                        <br>
                                        <div class="mt-2">
                                            <a href="https://pigeon-fw.com/privacy" target="_blank">プライバシーポリシー</a>
                                            <a href="https://pigeon-fw.com/specified" target="_blank" class="ml-3">特定商取引法に基づく表記</a>
                                        </div>
                                    </div>
                                </div>

                                <div class="radio-group m-auto text-center" *ngIf="agreed">
                                    <!--                                    <div class="d-inline-block">-->
                                    <!--                                        <input class="pg-radio" type="radio" name="pay_type" id="pay_as_credit_card"-->
                                    <!--                                               value="pay_as_credit_card" [(ngModel)]="pay_type">-->
                                    <!--                                        <label class="radio-custom" for="pay_as_credit_card">クレジットカード支払い(Paypal)</label>-->
                                    <!--                                    </div>-->
                                    <div class="d-inline-block">
                                        <input class="pg-radio" type="radio" name="pay_type" id="pay_as_stripe"
                                               value="pay_as_stripe" [(ngModel)]="pay_type">
                                        <label class="radio-custom" for="pay_as_stripe">クレジットカード支払い</label>
                                    </div>
                                    <div class="d-inline-block">
                                        <input class="pg-radio" type="radio" name="pay_type" id="pay_as_bank"
                                               value="pay_as_bank" [(ngModel)]="pay_type">
                                        <label class="radio-custom" for="pay_as_bank">銀行振込</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-12 text-center paypal-btn-padding" [hidden]="!agreed || pay_type != 'pay_as_credit_card'">
                                        <div id="{{getPaypalButtonId()}}">

                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center mt-4" [hidden]="!agreed || pay_type != 'pay_as_stripe' ">
                                        <div class="stripe-subscription">


                                            <button class="btn btn-success"
                                                    (click)="makePayment()"
                                                    style=" width: 100%; padding: 15px; background: #ffc439; font-size: 20px; border-color: #ffc439; color: #000; font-weight: bold;">
                                                クレジットカード支払い
                                            </button>
                                        </div>
                                    </div>
                                    <div class="col-md-12 text-center mt-4" *ngIf="agreed && pay_type == 'pay_as_bank' ">
                                        <button class="btn btn-success"
                                                (click)="sendNotiToUserPayAsBank.show()">銀行振込で有料登録申請を行う
                                        </button>
                                    </div>
                                </div>
                            </ng-template>

                        </ng-template>
                    </div>
                </div>
            </div>
        </div>
    </ng-template>

</div>

<confirm-modal #updatePaymentModal [submit_button_text]="'契約更新を行う'"
               [text]="'プランを更新します。よろしいですか？'" (onOk)="sendUpdateRequest()" [sending]="sending"></confirm-modal>

<confirm-modal #sendNotiToUserPayAsBank [submit_button_text]="'Yes'"
               [text]="'銀行振込で有料登録を行います。よろしいですか？'" (onOk)="sendNotiToUserAsPayAsBank()" [sending]="notiSending"></confirm-modal>

