<div class="modal-dialog modal-default" role="document">
    <div class="modal-content">
        <div class="modal-body">
            <tabset>
                <!-- 基本設定タブ -->
                <tab heading="基本設定" (selectTab)="changeTab('basic')" [active]="activeTab === 'basic'">
                    <div class="mt-3">
                        <h5>フィルター基本設定</h5>
                        <div class="form-group mb-3">
                            <label>フィルター名</label>
                            <input type="text" class="form-control" [(ngModel)]="edittingCustomFilter.name" placeholder="フィルター名を入力してください">
                        </div>
                    </div>
                </tab>

                <!-- 項目タブ -->
                <tab heading="項目" (selectTab)="changeTab('fields')" [active]="activeTab === 'fields'" cdkDropList (cdkDropListDropped)="drop($event)">
                    <div class="mt-3">
                        <h5>マップ情報ウィンドウの表示項目</h5>
                        <p>表示項目が空の場合はすべての項目が表示され、表示項目が指定してある場合はその項目のみ表示されます。</p>
                        <p>表示項目は10個までしか選択できません。</p>

                        <field-select-dragdrop 
                            [table_info]="table_info" 
                            [selected_field_name_a]="edittingCustomFilter.info_modal_fields"
                            [maxSelectionLimit]="10"
                            (onChangeValue)="onInfoModalFieldsChanged($event)">
                        </field-select-dragdrop>
                    </div>
                </tab>

                <!-- 色をつけるタブ -->
                <tab heading="色をつける" (selectTab)="changeTab('colors')" [active]="activeTab === 'colors'">
                    <div class="mt-3">
                        <h5>マーカーの色設定</h5>
                        <p>条件に一致するマーカーの色を変更できます。</p>
                        
                        <div class="marker-color-settings">
                            <button type="button" class="btn btn-primary mb-3" (click)="addMarkerColor()">色設定を追加</button>
                            <p class="text-muted">設定が登録されていない場合、すべてのマーカーは赤色で表示されます。</p>

                            <div *ngFor="let colorSetting of edittingCustomFilter.marker_colors; let i = index" class="mb-3 p-3 border rounded">
                                <div class="d-flex align-items-center mb-2">
                                    <img [src]="'https://maps.google.com/mapfiles/ms/icons/' + colorSetting.color + '-dot.png'" 
                                         alt="marker preview" 
                                         style="width: 32px; height: 32px; margin-right: 10px;">
                                    <span>この色のマーカー</span>
                                </div>

                                <div class="form-group">
                                    <label>マーカーの色</label>
                                    <select class="form-control" [(ngModel)]="colorSetting.color">
                                        <option value="red">赤</option>
                                        <option value="blue">青</option>
                                        <option value="green">緑</option>
                                        <option value="yellow">黄</option>
                                        <option value="purple">紫</option>
                                        <option value="pink">ピンク</option>
                                        <option value="orange">オレンジ</option>
                                        <option value="ltblue">ライトブルー</option>
                                    </select>
                                </div>

                                <div class="conditions-section">
                                    <h6>条件設定</h6>
                                    <button class="btn btn-success btn-sm mb-2" (click)="addCondition(colorSetting)">
                                        <i class="fa fa-plus mr-1"></i>条件を追加
                                    </button>

                                    <div class="mb-1" *ngFor="let condition of colorSetting.conditions?.condition_a; let conditionIndex = index">
                                        <div class="condition-wrap">
                                            <condition-form 
                                                [table_info]="table_info"
                                                [table]="table_info.table"
                                                [default_condition]="condition"
                                                [index]="conditionIndex"
                                                [forms]="table_info.forms"
                                                (valueChanged)="onConditionChanged($event, i)">
                                            </condition-form>
                                            <div class="buttons">
                                                <button class="btn btn-danger btn-sm mt-1" (click)="removeCondition(colorSetting, conditionIndex)">
                                                    <i class="fa fa-minus"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <button type="button" class="btn btn-danger btn-sm mt-2" (click)="removeMarkerColor(i)">この色設定を削除</button>
                            </div>
                        </div>
                    </div>
                </tab>
            </tabset>
        </div>
        <div class="modal-footer">
            <button type="button" class="btn btn-primary text-bold" (click)="save()">保存</button>
            <button type="button" class="btn btn-warning text-bold" (click)="preview()">表示</button>
            <button type="button" class="btn btn-secondary text-bold" (click)="onClickCancelButton.emit()">キャンセル</button>
        </div>
    </div>
</div> 