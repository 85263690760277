<div bsModal [config]="{backdrop: 'static', keyboard: false}" #modal='bs-modal' class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-primary" role="document" *ngIf="block && block.type == blockClass.TYPE_DELETE">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">トリガ設定</h4>
            </div>
            <div class="modal-body">
                <!-- ブロック名のinput -->

                ブロック名：<input type="text" class="form-control" [(ngModel)]="block.name" placeholder="ブロック名を入力してください。">


                <!-- 省略: データイベントタイプの選択 -->
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="modal.hide()">キャンセル</button>
                <button type="button" class="btn btn-success" (click)="save()">保存</button>
            </div>
        </div>
    </div>
</div>

