import {
    ChangeDetectorRef,
    Component,
    Input,
    OnInit,
    NgModule,
    ViewChild,
    ElementRef,
    TemplateRef,
    ChangeDetectionStrategy,
    OnChanges,
    SimpleChanges,
    SecurityContext,
    HostListener
} from '@angular/core';
import {SharedService} from 'app/services/shared';
import {Router} from '@angular/router';
import {Data} from '../class/Data';
import {Connect} from '../services/connect';
import {TableInfo} from '../class/TableInfo';
import * as FileSaver from 'file-saver';
import {Lightbox, LightboxConfig} from 'ngx-lightbox';
import ToastrService from '../toastr-service-wrapper.service';
import {ModalModule, BsModalRef, BsModalService} from 'ngx-bootstrap/modal';
import {GrantGroupData} from '../class/GrantGroupData';
import {Form} from '../class/Form';
import {Observable} from 'rxjs/Observable';
import {NgDompurifySanitizer} from '@tinkoff/ng-dompurify';
import {LightboxEvent, LIGHTBOX_EVENT} from 'ngx-lightbox';
import {Subscription} from 'rxjs';
import {CrossTableHeader} from '../class/CrossTableHeader';



@Component({
    selector: 'dynamic-data-view',
    templateUrl: './dynamic-data-view.component.html',
    styleUrls: ['./dynamic-data-view.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})


export class DynamicDataViewComponent implements OnChanges, OnInit {
    @Input() table_info: TableInfo;
    @Input() up: Function;
    @Input() down: Function;
    @Input() dataType;
    @Input() data: Data;
    @Input() field_name: string;
    @Input() download: Function;
    @Input() loading = false;
    @Input() nolink: boolean = false;
    @Input() crossTableHeader: CrossTableHeader;

    @Input() update: Observable<any>;

    //optional
    @Input() grantGroupData: GrantGroupData = null;
    @Input() loadGrantGroupAuto: boolean = true

    @Input() is_view_mode: boolean = false;

    private downloading: string = null;
    private isdownloading: boolean = false;
    private isdownloadpdf: string = '1';
    public arrayValue: Array<any>;
    public rawArrayValue: Array<any>;
    public isTextField: boolean = false;
    public form: Form;

    public calendarLoading = false;

    public page = 1;

    public pageLabel!: string;

    public ispdf: boolean = false;
    public google_calendar_setting: boolean = false;

    src = '';

    //GrantGruop

    private toasterService: ToastrService;
    private lightboxIndex: number = null;

    modalRef: BsModalRef;

    constructor(public _share: SharedService, private _router: Router, private _connect: Connect,
                private _lightbox: Lightbox, private _lightboxConfig: LightboxConfig, public changeDetectorRef: ChangeDetectorRef,
                toasterService: ToastrService, private modalService: BsModalService, private cd: ChangeDetectorRef,
                private readonly dompurifySanitizer: NgDompurifySanitizer, private _lightboxEvent: LightboxEvent) {
        this.toasterService = toasterService
        _lightboxConfig.centerVertically = true;
    }

    ngOnInit(): void {
        if (this.update) {
            this.update.subscribe(value => {
                this.cd.markForCheck();
            });
        }
    }

    is_string(data) {
        return data instanceof String;
    }

    download_file(url, no_action_log = false, file_name: string = null, isdownload = '1') {
        this.downloading = url;
        this._share.download_file(url, () => {
            this.downloading = null;
            this.changeDetectorRef.detectChanges();
        }, no_action_log, file_name, isdownload);
    }

    async showFileIfPossibleClicked(url, file_name, data, template: TemplateRef<any>) {
        let fileExt = file_name.split('.').pop();
        let image_extensions = ['jpg', 'jpeg', 'png'];
        let video_extensions = ['mp4', 'webm', 'mov', 'wmv', 'mkv'];
        let other_extensions = ['pdf'];
        let allowed_extensions = image_extensions.concat(video_extensions);
        allowed_extensions = allowed_extensions.concat(other_extensions);
        if(!allowed_extensions.includes(fileExt)) {
            this.download_file(url,false, file_name);
            return;
        }
        let form_array = this.table_info.forms.getArray();
        let field_name_array: string[] = [];
        // get forms when custom field, show_file, is true
        for (let i = 0; i < form_array.length; i++) {
            if (form_array[i]['_custom_field']) {
                if(form_array[i]['_custom_field']['show_file']) {
                    if(form_array[i]['field']['Field']==this.field_name)
                        this.isdownloadpdf="0";
                    field_name_array.push(form_array[i]['field']['Field']);
                }
            }
        }

        if(field_name_array.length > 0) {
            if(image_extensions.includes(fileExt)) {
                let images: { src: string, thumb: string }[] = [];
                const src = url;
                const thumb = url + '/thumbnail';

                let image = {
                    src: src,
                    thumb: thumb
                };
                images.push(image);
                this._lightbox.open(images, 0);
            }
            if(video_extensions.includes(fileExt)) {
                let video_url = '';
                await this.getPresignedURL(url, data.id).then( res => video_url = res.body.presignedUrl);
                this.src = video_url;
                this.modalRef = this.modalService.show(template);
            }
            if(other_extensions.includes(fileExt)) {
                this.download_file(url,false, file_name,this.isdownloadpdf);
                return;
            }
        }
        else {
            // show file condition is false
            this.download_file(url,false, file_name);
        }
    }

     getPresignedURL(url, id) {
        return this._connect.get('/admin/get-presigned-url',
            {
                'url': url,
                'id' : id,
            },
            {observe: 'response'}
        ).toPromise();
    }

    toDetail(link_data) {
        this._router.navigate([this._share.getAdminTable(), link_data['table'], 'view', link_data['data']]);
    }

    private getArrayValue(is_raw: boolean = false): Array<string> {
        if (!this.getValue(is_raw)) {
            return []
        }
        if (!Array.isArray(this.getValue(is_raw))) {
            return [this.getValue(is_raw)];
        }
        return this.getValue(is_raw);

    }

    getValue(is_raw: boolean = false) {
        if (!this.field_name) {
            return null
        }
        if (is_raw) {
            return this.data.raw_data[this.field_name]
        }
        return this.data.view_data[this.field_name];
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isTextField = ['image', 'thumbnail', 'file', 'url', 'number', 'richtext', 'grant_group'].indexOf(this.dataType) == -1;
        if (['image', 'file'].indexOf(this.dataType) !== -1) {
            if (this.data && this.data.table_info) {
                this.arrayValue = this.getArrayValue();
                this.rawArrayValue = this.getArrayValue(true);
            }
        } else if (!this.isTextField) {
            if (this.data && this.data.table_info) {
                let form = this.data.table_info.forms.byFieldName(this.field_name);
                if (form && form.is_multi_value_mode && !!this.data.child_data_by_table[form.multiple_table_name]) {
                    this.arrayValue = this.data.child_data_by_table[form.multiple_table_name].map(d => d.view_data['value'])
                    this.rawArrayValue = this.data.child_data_by_table[form.multiple_table_name].map(d => d.raw_data['value'])
                } else {
                    this.arrayValue = this.getArrayValue();
                    this.rawArrayValue = this.getArrayValue(true);
                }
            }
        }

        if (this.field_name) {
            this.form = this.table_info.forms.byFieldName(this.field_name)
        }

        if (this.dataType === 'grant_group' && this.loadGrantGroupAuto && this.data && this.data.raw_data[this.field_name]) {
            this._connect.get('/admin/view/grant_group/' + this.data.raw_data[this.field_name]).subscribe((data) => {
                this._share.getTableInfo('grant_group').subscribe(_table_info => {
                    this.grantGroupData = new GrantGroupData(_table_info)
                    this.grantGroupData.setInstanceData(data.data)
                    this.changeDetectorRef.detectChanges();
                })
            });
        }

    }

    getSanitizedValue(value): string {
        return this.dompurifySanitizer.sanitize(SecurityContext.HTML, value).replace(/<a /g, '<a target=\'_blank\'');
    }

    is_show_download() {
        //1週間前まで
        let date = new Date(this.data.raw_data['created']);

        let week_ago = new Date();
        week_ago.setDate(week_ago.getDate() - 7)
        console.log(date)
        console.log(week_ago)

        return date > week_ago

    }

    download_csv() {
        //console.time('download_csv:');
        let csv_id = this.data.raw_data['id']

        const url = this._connect.getApiUrl() + '/admin/download-csv/' + csv_id;
        this.downloading = url;
        this._connect.get(url, null, {'responseType': 'blob'}).subscribe((data: any) => {
            this.downloading = null;
            this.changeDetectorRef.detectChanges();
            if (data.size === 0) {
                alert('ダウンロードに失敗しました。権限を確認して下さい。');
                return;
            }
            const blob = new Blob([data], {type: 'text/csv'});
            //let filename = this.table_info.getLabel() + '_' + this._share.dateFormat.format(new Date(), 'yyyyMMdd_hhmm') + '.csv';
            FileSaver.saveAs(blob, this.data.raw_data['filename']);
        })

        //console.timeEnd('download_csv:');
    }


    view() {
        this._router.navigate([this._share.getAdminTable(), this.table_info.table, 'view', this.data.raw_data['id']]);
    }


    openImg(i: number) {
        let album = this.arrayValue.map(file_info => {
            return {
                src: file_info.url,
                thumb: file_info.thumbnail_url,
                caption: file_info.name,
            }
        })
        // album = album.filter((fileinfo,num)=>{
        //     return num==i
        // })
        this.lightboxIndex = i
        this._lightbox.open(album, i, {showZoom: true, centerVertically: true, disableScrolling: true, fadeDuration: 0, resizeDuration: 0.2, enableTransition: false});
        this._subscription = this._lightboxEvent.lightboxEvent$
            .subscribe(event => this._onReceivedEvent(event));
    }


    private _subscription: Subscription;

    private _onReceivedEvent(event: any): void {
        console.log(event)
        // remember to unsubscribe the event when lightbox is closed
        if (event.id === LIGHTBOX_EVENT.CLOSE) {
            // event CLOSED is fired
            this._subscription.unsubscribe();
        }

        if (event.id === LIGHTBOX_EVENT.OPEN) {
            // event OPEN is fired
        }

        if (event.id === LIGHTBOX_EVENT.CHANGE_PAGE) {
            // event change page is fired
            console.log(event.data); // -> image index that lightbox is switched to
            if (event.data == this.lightboxIndex) {
                return;
            }
            this._subscription.unsubscribe();
            this._lightbox.close()
            this.openImg(event.data)
        }
    }

    clickLink(url: string) {
        // 20240509 Kanazawa 追加
        // templateから渡される時stringを担保できないので追加（他の箇所で同様の処理をしているのを確認）
        url = url.toString()
        url = url.trim();
        if (!url.match(/^http/)) {
            this._share.copyMessage(this.getLink(url))
            this.toasterService.success('URLをコピーしました')
        } else {
            window.open(this.getLink(this.getLink(url)), '_blank')
        }
    }

    isUrl(url: string) {
        if (!url) {
            return false;
        }
        url = url.toString()
        url = url.trim();

        // スペースがある場合は false を返す
        // https://loftal.slack.com/archives/C05CK6Z7YDQ/p1695620082106199 の対応で消す
        // if (url.includes(' ')) {
        //     return false;
        // }

        return url && (url.match(/^http/) || url.match(/^\\/) || url.match(/^file:/))
    }

    getLink(path: string) {
        if (path.match(/,/)) {
            //for dezie
            return path.split(',')[0]
        }
        return path
    }

    getFileName(path: string) {
        if (path.match(/,/)) {
            //for dezie
            return path.split(',')[1]
        }
        return path
    }

    isPdfFile(filename) {
        return filename && filename.match(/.pdf$/)
    }

    isDownloading(_url: string): boolean {
        return this.downloading == _url
    }

    textToHtml(text: string) {
        //convert text to urlencode
        if (!text) {
            return text;
        }
        if (this.dataType == 'text') {
            return this.encodeHTML(text).replace(/ /g, '&nbsp;');
        }

        if (['textarea', 'email'].indexOf(this.dataType) == -1) {
            return this.encodeHTML(text);
        }
        text = this.textToUrl(text);
        text = this.textToEmail(text);
        return text;
    }

    textToUrl(text: string) {
        let reg = /(https?:\/\/[^\s]+)/g;
        //check text is string
        if (!text) {
            return text;
        }
        text = text.toString()
        return text.replace(reg, function (url) {
            return '<a href="' + url + '" target="_blank">' + url + '</a>';
        })
    }

    textToEmail(text: string) {
        let reg = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9._-]+)/gi;
        return text.replace(reg, function (mail) {
            return '<a href="mailto:' + mail + '">' + mail + '</a>';
        });
    }


    encodeHTML(str) {
        return str.toString().replace(/[\u00A0-\u9999<>\&]/g, function (i) {
            return '&#' + i.charCodeAt(0) + ';';
        });
    }


    googleAuth(){
        this.google_calendar_setting = true;
    }

    handleGoogleFileInput(files: FileList, calendar_id: string) {

        if(!calendar_id){
            this.toasterService.error('カレンダーIDは必須です');
            return;
        }
        if(!files){
            this.toasterService.error('jsonファイルを選択してください');
            return;
        }
        const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
        if (!emailPattern.test(calendar_id)) {
            this.toasterService.error('形式が正しくありません。');
            return;
        }
        if (files && files.length > 0) {
            this.calendarLoading = true;

            const fileToUpload = files.item(0);
            const url = this._connect.getApiUrl() + '/admin/google-calendar-setting';
            const formData: FormData = new FormData();
            formData.append('json', fileToUpload, fileToUpload.name);
            formData.append('calendar_id', calendar_id);
            this._connect.postUpload(url, formData).subscribe((response) => {
                this.calendarLoading = false;
                this.toasterService.success('Google 連携が完了しました。')
                this.google_calendar_setting = false;
                this.changeDetectorRef.detectChanges()
                window.location.reload();
            }, (error) => {
                this.calendarLoading = false;
                this.changeDetectorRef.detectChanges()
                this.toasterService.error(error.error.error_message + ':::手順書の確認と、カレンダーIDを確認してください。')
            })
        }
    }


}
