<div bsModal #confirmModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-{{type}}" role="document" [ngClass]="{'iframe':is_iframe}">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title"><i class="fa fa-exclamation-triangle" aria-hidden="true"></i> {{title}}</h4>
                <button type="button" class="close" (click)="confirmModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <p>{{text}}</p>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary" (click)="confirmModal.hide() && onClickCancel()">キャンセル</button>
                <button id="confirm-submit-btn" type="button" class="btn btn-{{type}}" (click)="onClickOk()" [ladda]="sending">{{ submit_button_text }}</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
