import {Component, OnInit} from '@angular/core';
import {BlockModalBaseComponent} from '../block-modal-base/block-modal-base.component';
import {Block, CreateBlock, UpdateBlock} from '../flow.component';
import {cloneDeep} from 'lodash';

@Component({
    selector: 'app-create-edit-modal',
    templateUrl: './create-edit-modal.component.html',
    styleUrls: ['./create-edit-modal.component.scss']
})
export class CreateEditModalComponent extends BlockModalBaseComponent implements OnInit {
    public block: CreateBlock;

    public readonly Block = Block;

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
        if (changes.srcBlock) {
            this.block = cloneDeep(this.srcBlock) as CreateBlock;
        }
        super.ngOnChanges(changes);
    }

    public save() {
        super.save(this.block);
    }

    ngOnInit(): void {
    }

}
