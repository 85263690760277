<div>

    <div>
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['table_editable']"
                   [checked]="isCheckGrant('table_editable')"
            >
            <i class="ml-2 mr-1 fa fa-lock"></i>テーブル項目設定
            <i class="fa fa-question-circle mt-2 ml-2" aria-hidden="true" ngbTooltip="テーブルの項目や権限を設定可能" container="body"></i>
        </label>
        <br>
        <label *ngIf="grant_value['table_editable']">
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['table_author']"
                   [checked]="isCheckGrant('table_author')"
            >
            <i class="ml-2 mr-1 fa fa-lock"></i>テーブル管理者
        </label>
    </div>

    <ng-container *ngIf="!grant_value['table_editable']">
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['view']" [disabled]="view_grant_force_true"
                   [checked]="isCheckGrant('view')">
            閲覧
        </label>
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['edit']"
                   [checked]="isCheckGrant('edit')|| isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') ">
            編集
        </label>
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['add']"
                   [checked]="isCheckGrant('add') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') || isCheckGrant('only_one')">
            追加
        </label>
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['delete']"
                   [checked]="isCheckGrant('delete') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') || isCheckGrant('only_one')">
            削除
        </label>
        &nbsp;
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['summarize']"
                   [checked]="isCheckGrant('summarize') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') || isCheckGrant('only_one')">
            集計
        </label>
        &nbsp;
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['duplicate']"
                   [checked]="isCheckGrant('duplicate') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('add') || isCheckGrant('only_one')">
            複製
        </label>
        &nbsp;
        <label>
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['update_all']"
                   [checked]="isCheckGrant('update_all') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('edit') || isCheckGrant('only_one')">
            一括編集
        </label>
        <br>
        <label class="text-danger">
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['only_one']"
                   [checked]="isCheckGrant('only_one') "
                   [disabled]="!isCheckGrant('view')">
            <i class="ml-2 mr-1 fa fa-lock"></i>1データのみ登録可能
        </label>
        &nbsp;
        <label class="text-danger">
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['disable_csv_dl']"
                   [checked]="isCheckGrant('disable_csv_dl') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') || isCheckGrant('only_one')">
            <i class="ml-2 mr-1 fa fa-lock"></i>CSVダウンロード不可
        </label>
        &nbsp;
        <label class="text-danger">
            <input (change)="onChangeGrant()" type="checkbox" value="true" [(ngModel)]="grant_value['disable_csv_up']"
                   [checked]="isCheckGrant('disable_csv_up') && !isCheckGrant('only_one')"
                   [disabled]="!isCheckGrant('view') || isCheckGrant('only_one')">
            <i class="ml-2 mr-1 fa fa-lock"></i>CSVアップロード不可
        </label>

        <br>
        <ng-container *ngIf="is_show_table_grant">
            <!-- 閲覧条件 -->
            <button class="btn btn-success pull-left btn-md btn-sm" (click)="addGrantCondition()"><i class="fa fa-plus">&nbsp;閲覧条件 </i></button>
            <i class="fa fa-question-circle mt-2 ml-2" aria-hidden="true" ngbTooltip="条件設定すると、閲覧出来るデータを制限可能。設定しない場合は全て閲覧可能" container="body"></i>
            <br style="clear: both">
            <br style="clear: both">
            <div cdkDropList (cdkDropListDropped)="drop(grant_value,$event)">
                <ng-container *ngFor="let condition of conditions.condition_a; let cond_i = index;">
                    <div class="condition-item" cdkDrag style="margin-bottom:10px">
                        <div class="drag-handle pull-right" cdkDragHandle>
                            <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                <path d="M0 0h24v24H0z" fill="none"></path>
                            </svg>
                        </div>


                        <div class="condition-wrap" style="height:auto !important;">
                            <condition-form [is_grant_condition_form]="true" [table]="table" [default_condition]="condition" (valueChanged)="onGrantConditionChange(cond_i,$event)"
                                            [index]="cond_i"></condition-form>
                            <div class="buttons">
                                <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delGrantCondition(cond_i)"><i class="fa fa-minus"></i></button>
                            </div>
                        </div>
                    </div>
                </ng-container>
            </div>
            <!-- 編集条件 -->
            <ng-container *ngIf="isCheckGrant('edit')">
                <button class="btn btn-success pull-left btn-md btn-sm" (click)="addGrantCondition('edit_condition_a')"><i class="fa fa-plus">&nbsp;編集条件</i>
                </button>
                <i class="fa fa-question-circle mt-2 ml-2" aria-hidden="true" ngbTooltip="条件設定すると、編集出来るデータを制限可能。設定しない場合は閲覧出来るデータは全て編集可能" container="body"></i>
                <br style="clear: both">
                <br style="clear: both">
                <div cdkDropList (cdkDropListDropped)="drop(grant_value,$event,'edit_condition_a')">
                    <ng-container *ngFor="let condition of edit_conditions.condition_a; let cond_i = index;">
                        <div class="condition-item" cdkDrag style="margin-bottom:10px">
                            <div class="drag-handle pull-right" cdkDragHandle>
                                <svg width="24px" fill="currentColor" viewBox="0 0 24 24">
                                    <path d="M10 9h4V6h3l-5-5-5 5h3v3zm-1 1H6V7l-5 5 5 5v-3h3v-4zm14 2l-5-5v3h-3v4h3v3l5-5zm-9 3h-4v3H7l5 5 5-5h-3v-3z"></path>
                                    <path d="M0 0h24v24H0z" fill="none"></path>
                                </svg>
                            </div>

                            <div class="condition-wrap" style="">
                                <condition-form [table]="table" [default_condition]="condition"
                                                (valueChanged)="onGrantConditionChange(cond_i,$event,'edit_condition_a')" [index]="cond_i"></condition-form>
                                <div class="buttons">
                                    <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delGrantCondition(cond_i,'edit_condition_a')"><i class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-container>


            <!-- for admin table -->
            <div class="row mb-2" *ngIf="table_info&& table_info.table==='admin'">
                <div class="form-control-label col-md-3"><label> 非表示項目<br>(デフォルト項目) </label>
                    <button (click)="addAdminTableHideField()" class="btn-success btn btn-sm pull-right ng-star-inserted"><i class="fa fa-plus"></i></button>
                    <br class="ng-star-inserted">
                </div>
                <div class="form-control-label col-md-9">
                    <ng-container *ngFor="let admin_table_hide_field of admin_table_hide_fields; let cond_i = index;">
                        <div class="row" style="z-index:10000">
                            <div class="col-lg-9" style="margin-bottom:5px;">
                                <select style="width:100%" class="form-control mt-1" (change)="onChangeGrant()" [(ngModel)]="admin_table_hide_field['field']">
                                    <ng-container *ngFor="let field of table_info.fields">
                                        <option *ngIf="showForDfault(field)" value="{{field.Field}}">{{table_info.forms.byFieldName(field.Field).label}}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="col-lg-1">
                                <div class="buttons">
                                    <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delAdminTableHideField(cond_i)"><i class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>


                <div class="form-control-label col-md-3"><label> 編集不可項目<br>(デフォルト項目) </label>
                    <button (click)="addAdminTableNoEditField()" class="btn-success btn btn-sm pull-right ng-star-inserted"><i class="fa fa-plus"></i></button>
                    <br class="ng-star-inserted">
                </div>
                <div class="form-control-label col-md-9">
                    <ng-container *ngFor="let admin_table_no_edit_field of admin_table_no_edit_fields; let cond_i = index;">
                        <div class="row" style="z-index:10000">
                            <div class="col-lg-9" style="margin-bottom:5px;">
                                <select style="width:100%" class="form-control mt-1" (change)="onChangeGrant()" [(ngModel)]="admin_table_no_edit_field['field']">
                                    <ng-container *ngFor="let field of table_info.fields">
                                        <option *ngIf="showForDfault(field)" value="{{field.Field}}">{{table_info.forms.byFieldName(field.Field).label}}</option>
                                    </ng-container>
                                </select>
                            </div>
                            <div class="col-lg-1">
                                <div class="buttons">
                                    <button class="btn btn-danger pull-left btn-sm btn mt-1" (click)="delAdminTableNoEditField(cond_i)"><i class="fa fa-minus"></i></button>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>


        </ng-container>

    </ng-container>

</div>
