<div bsModal #indexWorkflowModal="bs-modal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel"
     aria-hidden="true">
    <div class="modal-dialog modal-primary" [ngClass]="{'modal-danger':workflow_status=='rejected','modal-primary':workflow_status=='accepted','modal-warning':workflow_status=='withdraw'}"
         role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title">
                    <span *ngIf="workflow_status=='accepted'">申請を承認します。</span>
                    <span *ngIf="workflow_status=='rejected'">申請を拒否します。</span>
                </h4>
                <button type="button" class="close" (click)="indexWorkflowModal.hide()" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div>
                    <h5>コメント（任意）</h5>
                    <textarea [(ngModel)]="workflow_comment" style="height:200px" class="form-control"></textarea>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" [ladda]='_share.isLoading(loading_obj)' class="btn btn-secondary btn-ladda" (click)="indexWorkflowModal.hide()">キャンセル</button>

                <button [ladda]='sending' *ngIf="workflow_status=='accepted'" type="button" class="btn btn-success btn-ladda" (click)="onClickOk()">承認</button>
                <button [ladda]='sending' *ngIf="workflow_status=='rejected'" type="button" class="btn btn-danger btn-ladda" (click)="onClickOk()">否認</button>
                <button [ladda]='sending' *ngIf="workflow_status=='withdraw'" type="button" class="btn btn-warning btn-ladda" (click)="onClickOk()">取り下げ</button>
            </div>
        </div><!-- /.modal-content -->
    </div><!-- /.modal-dialog -->
</div><!-- /.modal -->
