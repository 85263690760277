import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-create-block',
    templateUrl: './create-block.component.html',
    styleUrls: ['./create-block.component.scss']
})
export class CreateBlockComponent implements OnInit {

    constructor() {
    }

    ngOnInit(): void {
    }

}
