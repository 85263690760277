import { Component, OnInit, ViewChild, Input, Output, EventEmitter } from '@angular/core';
import { Connect } from '../services/connect';
import {ActivatedRoute} from '@angular/router'
import ToastrService from '../toastr-service-wrapper.service';


@Component({
  selector: 'expense-allocation',
  templateUrl: './expense-allocation.component.html',
  styleUrls: ['./expense-allocation.component.css']
})
export class ExpenseAllocationComponent implements OnInit {

  constructor(
    private _connect: Connect,
    private _route: ActivatedRoute,
    private toasterService: ToastrService) { }
  
  public price_kind =[];
  public prices = [];
  public total_price = null;
  public branc_con_fields: any = null;
  public expense_amount: number = null;
  public totalPercentage: number = 0;
  public sending = false;


  @Input() expense_allocation_data: any = null;
  @Input() table: string;

  @ViewChild('expenseAllocationModal') expenseAllocationModal: any;
  @Output() load: EventEmitter<any> = new EventEmitter();


  
  ngOnInit(): void {
  }

  ngOnChanges() {
    if(this.branc_con_fields) return;
    this._connect.get('/admin/bran-con/fields/' + this.table, null, null, true).subscribe((data_hash) => {
      this.branc_con_fields = data_hash.fields
    })
  }

  hide() {
    // this.sending = false;
    this.expenseAllocationModal.hide()
  }
  show() {
    this.expenseAllocationModal.show()
  }

  onSelectionChange(id, kind, i){
    this.price_kind[id] = kind;
    this.prices[id] = this.expense_allocation_data[i].raw_data['field__' + this.branc_con_fields[kind]['id']];
    this.total_price = Object.keys(this.prices).reduce((acc, key) => acc + Number(this.prices[key]), 0);
    this.calculateTotalPercentage()

  }

  onAmountChange() {
    this.calculateTotalPercentage()

  }

  setApportionedAmountExcludingTax(i, item, type) {
    if (!this.branc_con_fields || !type) return;
    this.expense_allocation_data[i]['raw_data']['field__' + this.branc_con_fields['apportioned_amount_excluding_tax']['id']]
      = ((item.raw_data['field__' + this.branc_con_fields[type]['id']] / this.total_price) * this.expense_amount).toFixed(2);
    return this.expense_allocation_data[i]['raw_data']['field__' + this.branc_con_fields['apportioned_amount_excluding_tax']['id']]


  }

  calculateTotalPercentage() {
    let sum = 0;
    this.expense_allocation_data.forEach(item => {
      if (this.price_kind[item.raw_data['id']] === 'branc_cost_amount') {
        sum += (item.raw_data['field__' + this.branc_con_fields['branc_cost_amount']['id']] / this.total_price) * 100;
      } else if (this.price_kind[item.raw_data['id']] === 'branc_buy_amount') {
        sum += (item.raw_data['field__' + this.branc_con_fields['branc_buy_amount']['id']] / this.total_price) * 100;
      }
    });
    this.totalPercentage = sum;
  }

  submit(){
    this.sending = true;
    const raw_data_a = this.expense_allocation_data.map(item => item.raw_data);

    // 按分金額(税抜)の型だけ確認、今は整数でインポートされるのを小数に変えてる
    this._connect.post('/admin/bran-con/expense_allocation', { raw_data_a: raw_data_a, table: this.table }).subscribe((data) => {
      console.log(data)
      this.sending = false;
      this.load.emit();      
      this.hide();

    }, (error) => {
      this.sending = false;
    })
  }

}
