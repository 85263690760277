import {ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TableInfo} from '../class/TableInfo';
import {SharedService} from '../services/shared';
import ToastrService from '../toastr-service-wrapper.service';
import {Data} from '../class/Data';
import {Connect} from 'app/services/connect';
import {Form} from '../class/Form';
import {SelectOptionItemsFilter} from '../class/SelectOptionItemsFilter';
import {Observable} from 'rxjs/Observable';
import {FormEditData} from '../class/FormEditData';
import {CrossTableHeader} from '../class/CrossTableHeader';


@Component({
    selector: '[dataset-table-cell]',
    templateUrl: './dataset-table-cell.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
})

export class DatasetTableCellComponent implements OnInit, OnChanges {

    @Input() form: Form;
    @Input() field;
    @Input() grant_menu_a: Array<any>;
    @Input() selectChange;
    @Input() cellId: string;
    @Input() dataType: string;
    @Input() data: Data;
    @Input() selectedCellId;
    @Input() primary_key: number;
    @Input() showFormEditModal: Function;
    @Input() loading = false;
    @Input() table_info: TableInfo;
    @Input() data_index: number;
    @Input() child_a: Array<any>;
    @Input() isEditMode: boolean;
    @Input() crossTableHeader: CrossTableHeader;

    @Input() selectOptionItemsFilter: SelectOptionItemsFilter;


    @Input() update: Observable<any>;

    @Input() embedMode: boolean = false;
    @Input() viewDataMode: boolean = false;

    @Output() valueChanged: EventEmitter<Object> = new EventEmitter();
    @Output() onFormatViewData: EventEmitter<Object> = new EventEmitter();
    @Output() ctrlClickEvent: EventEmitter<any> = new EventEmitter();

    private toasterService: ToastrService;
    private connect;

    public isShowEditIcon: boolean = false;
    public isShowEditForm: boolean = false;
    public isCtrlClickAllow: boolean = false;

    constructor(public _share: SharedService, toasterService: ToastrService, connect: Connect, private cd: ChangeDetectorRef) {
        this.toasterService = toasterService;
        this.connect = connect;
    }


    ngOnInit() {
        this.update.subscribe(value => {
            this.cd.markForCheck();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.isCtrlClickAllow = (!!!this.field['fixed_value'] && this.form && !this.form.is_multi_value_mode) && (!['calc'].includes(this.dataType)) && (!['id', 'updated', 'created', 'admin_id'].includes(this.field.Field) && this.data.isEditable())
        this.isShowEditIcon = this.isCtrlClickAllow && (this.cellId == this.selectedCellId || this.isEditMode) && this.form.is_show_by_condition && this.table_info.grant.isEditableField(this.field.Field) ? true : false

        //tmp
        this.isShowEditIcon = this.isShowEditIcon && ['richtext', 'textarea'].indexOf(this.form.original_type) == -1
    }

    isTemporaryAddData() {
        let m = this.cellId.match(/^(\-?\d+)_/)
        if (!m) {
            return false;
        }
        return parseInt(m[1]) < 1;
    }


    onValueChange($event) {
        // this.data.raw_data[$event.field_name] = $event.value;
        const updHash = {}
        updHash[$event.field_name] = $event.value
        this.data.setRawData(updHash)
        this.onFormatViewData.emit({
            data_index: this.data_index,
            field: this.field,
            form: this.form,
            raw_data: this.data.raw_data[this.field.Field]
        });
        this.valueChanged.emit({
            'data_index': this.data_index,
            'field': this.field,
            'form': this.form,
            'child_a': this.child_a,
            'value': this.data.raw_data[this.field.Field]
        })
    }


    getImageThumbnail(field_name) {
        if (this.data.raw_data['__images'] != undefined) {
            return this.data.raw_data['__images'][field_name];
        }
        return null;
    }


    isAutoFillField(): boolean {
        return this.table_info.copyto_fields.indexOf(this.field['Field']) >= 0 || this.form.isAutoFillField
    }


    @HostListener('click', ['$event'])
    onClick($event) {
        if (this.isEditMode && ['richtext', 'textarea'].includes(this.dataType)) {
            let formEditData: FormEditData = new FormEditData();
            formEditData.setByHash({
                is_setting: !this.table_info.grant.edit,
                data: this.data,
                field: this.field,
                form: this.form,
                table_info: this.table_info,
                grant_menu_a: this.grant_menu_a,
                data_index: this.data_index,
                selectChange: this.selectChange,
            })
            this.showFormEditModal(formEditData, this.data.raw_data['id']);
        } else {
            if (this.isShowEditIcon) {
                //ダブルクリックで編集モード
                this.isShowEditForm = true;

            }
        }

        if(!this.isEditMode) {
            return;
            if($event.ctrlKey || $event.metaKey) {
                if(this.isCtrlClickAllow) {
                    if(['richtext', 'textarea'].includes(this.dataType)) {
                        let formEditData = {
                            is_setting: !this.table_info.grant.edit,
                            data: this.data,
                            field: this.field,
                            form: this.form,
                            table_info: this.table_info,
                            grant_menu_a: this.grant_menu_a,
                            data_index: this.data_index,
                            selectChange: this.selectChange,
                        };
                        this.showFormEditModal(formEditData, this.data.raw_data['id']);
                    }
                    this.isShowEditForm = true;
                    this.ctrlClickEvent.emit($event);
                }
            }
        }
    }

    private zip_downloading: boolean = false;

    zip_download() {
        this.zip_downloading = true;
        var form_array = this.table_info.forms.getArray();
        var field_name_array: string[] = [];
        // get forms when original_type is file and multiple_value_mode is true
        for (var i = 0; i < form_array.length; i++) {
            if (form_array[i].is_multi_value_mode == true && form_array[i].original_type === 'file') {
                field_name_array.push(form_array[i]['field']['Field']);
            }
        }
        let form = this.form;
        let file_ids: Array<number> = this.data.raw_data[form.field['Field']];

        this._share.download_file(this.connect.getApiUrl() + '/admin/files/download-zip?file_ids=' + (file_ids).join(','), () => {

            this.zip_downloading = false;
        }, true, this.table_info.getLabel() + '_' + this.form.label + '_' + this.data.raw_data['id'] + '.zip');
    }


}
