<div bsModal #branConProsessTransModal="bs-modal" class="modal fade" tabindex="-1" role="dialog"
   aria-labelledby="myModalLabel" aria-hidden="true">
   <div class="modal-dialog modal-default" role="document">
      <div class="modal-content">
         <div class="modal-header">
            <h4 class="modal-title">
               加工振替
            </h4>
         </div>
         <div class="modal-body">
            <div class="flex align-items-center ml-4" *ngIf="trans_fields">
               <div class="flex branc_stock_number">
                  <div class="">
                     在籍番号
                  </div>
                  <div class="ml-24">
                     {{data.view_data['field__' + this.trans_fields['branc_stock_number'].id]}}
                  </div>
               </div>
               <div class="flex branc_class">
                  <div class="branc_class_label">
                     加工区分
                  </div>
                  <div class="ml-24 flex">
                     <div *ngFor="let option of class_options;index as i" class="radio" style="padding-top: 0px;">
                        <input type="radio" name="branc_class" id="branc_class{{i}}" class="pg-radio"
                           [(ngModel)]="prosess_class" [value]="option.value" [checked]="prosess_class == option.value">
                        <label class="radio-custom" for="branc_class{{i}}">
                           {{ option.label }}
                        </label>
                     </div>
                  </div>
               </div>
               <div class="flex branc_procuct_name">
                  <div class="w-60">
                     品名
                  </div>
                  <input [(ngModel)]="product_name" type="text" class="form-control">
               </div>
            </div>
            <div class="flex align-items-center ml-4">
               <div class="w-60 ">
                  投入重量
               </div>
               <input [(ngModel)]="input_weight" OnlyNumber type="text" class="form-control w-60 ml-24">&nbsp; kg
            </div>
            <div class="modal-footer mt-10">
               <button type="button" class="btn btn-secondary" (click)="hide()">キャンセル</button>
               <button type="button" class="btn btn-primary" (click)="submit()" [ladda]="sending">登録</button>
            </div>
         </div>
      </div>
   </div>
</div>